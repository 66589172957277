import { IFooterList } from 'types/Footer';
import {
  BRAND_ASSETS_LINK,
  DOC_LINK,
  FAQ_LINK,
  FEATURE_REQUEST_LINK,
  TROUBLE_SHOOTING_LINK,
  TUTORIALS_LINK
} from './constants';

export const mainFooter: IFooterList[] = [
  {
    title: 'Learn',
    links: [
      { name: 'Docs', href: '' },
      {
        name: 'Brand Assets',
        href: 'https://drive.google.com/drive/folders/11nMTpBt9B7Kogf321uIPj0KvSXXMGE3a?usp=sharing'
      }
    ]
  },
  {
    title: 'Product',
    links: [
      { name: 'Status', href: 'https://status.ethsign.xyz' },
      { name: 'Troubleshooting', href: 'https://discord.com/invite/XPBSU3CVaK' }
    ]
  }
];

export const tokenTableFooter: IFooterList[] = [
  {
    title: 'LEARN',
    links: [
      { name: 'Docs', href: DOC_LINK },
      { name: 'Tutorials', href: TUTORIALS_LINK },
      { name: 'Brand Assets', href: BRAND_ASSETS_LINK }
    ]
  },
  {
    title: 'PRODUCT',
    links: [
      { name: 'Feature request', href: FEATURE_REQUEST_LINK },
      { name: 'FAQs', href: FAQ_LINK },
      { name: 'Troubleshooting', href: TROUBLE_SHOOTING_LINK }
    ]
  }
];
