import axios, { AxiosInstance } from 'axios';

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ETHSIGN_API_ENDPOINT
});

instance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response.data)
);

export default instance;
