import classNames from 'classnames';
import { useState } from 'react';

import Founder1 from 'assets/images/Founders/founder-1.png';
import Founder2 from 'assets/images/Founders/founder-2.png';
import TokenManagement from 'assets/images/Founders/founder-3.png';

import { Button, ButtonVariant } from 'components/Button';
import { ArrowRight } from 'components/Icons/ArrowRightIcon';
import { CheckCircledIcon } from 'components/Icons/CheckCircledIcon';
import { navigate } from 'utils/utils';
import { TOKENTABLE_LINK } from 'utils/constants';
import { Title } from 'components/Title';

const tabs = [
  {
    key: 'planning',
    title: 'Create Token Distribution'
  },
  {
    key: 'fundraising',
    title: 'Manage Token Unlocking'
  },
  {
    key: 'management',
    title: 'FutureToken'
  }
];

const FoundersSection = () => {
  const [tab, setTab] = useState('planning');
  const handleNavigateToTokenTable = () => navigate(TOKENTABLE_LINK);

  return (
    <section className="relative w-full mt-[0px] md:mt-[80px]">
      <div className="w-full max-w-screen-xl bg-[#FDF7F3] mt-[0px] md:mt-[20px] mx-[auto] rounded-none md:rounded-[32px]">
        <div className="w-full text-center text-text text-[16px] font-semiBold pt-[40px] md:pt-[64px]">
          Manage less, build more
        </div>
        <h2 className="text-[32px] md:text-[40px] font-bold text-[#0D0D0D] text-center mt-[16px]">
          TokenTable for <Title>Founders</Title>
        </h2>
        <div className="flex flex-col items-center sm:mt-10 max-w-screen-xl mx-auto px-4 md:px-6 lg:px-20 pb-[40px] md:pb-[64px]">
          <div className="hidden md:grid grid-cols-3 w-auto mx-auto gap-[20px]">
            {tabs.map(({ key, title }) => (
              <div
                key={key}
                className={classNames(
                  'flex flex-row justify-center items-center cursor-pointer font-semiBold rounded-[8px] w-[250px] h-[48px]',
                  {
                    'border border-[grba(0,0,0,0.2)] bg-white text-black': tab !== key
                  },
                  {
                    'bg-tangerine-500 text-white': tab === key
                  }
                )}
                onClick={() => setTab(key)}
              >
                {title}
              </div>
            ))}
          </div>
          <div
            className={classNames(
              'w-full mt-10 md:mt-12 px-0 sm:px-[46px] lg:px-0',
              { '!hidden': tab !== 'planning' },
              'flex flex-col md:grid lg:grid grid-cols-2'
            )}
          >
            <div className="flex flex-col justify-center mt-6 sm:mt-0 lg:mr-[40px]">
              <h3 className="block md:hidden font-bold text-[24px] text-[#0D0D0D] mb-4">Create Token Distribution</h3>
              <p className="text-[16px] font-semiBold text-black mb-6">
                Effortlessly create unlocking schedules for different stakeholders
              </p>
              <ul className="space-y-4 mb-6">
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute top-[3px]" />
                  <span className="ml-8">
                    Quickly get setup by selecting from our curated token distribution templates
                  </span>
                </li>
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute top-[3px]" />
                  <span className="ml-8">Import token recipients via CSV file</span>
                </li>
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute top-[3px]" />
                  <span className="ml-8">Review your distributions in real-time via interactive charts</span>
                </li>
              </ul>
              <Button
                className="rounded-[100px]"
                variant={ButtonVariant.PRIMARY}
                iconRight={<ArrowRight />}
                onClick={handleNavigateToTokenTable}
              >
                Create Your Token Table Now
              </Button>
            </div>
            <div className="mt-10 md:mt-0 flex flex-row justify-center md:justify-end items-center">
              <img className="rounded-xl md:drop-shadow w-[70%] md:w-[400px]" src={Founder1} alt="Project Planning" />
            </div>
          </div>
          <div
            className={classNames(
              'tab-content w-full mt-12 md:mt-16 sm:px-[46px] lg:px-0',
              { '!hidden': tab !== 'fundraising' },
              'flex flex-col md:grid grid-cols-2'
            )}
          >
            <div className="flex flex-col justify-center lg:mr-[40px]">
              <h3 className="block md:hidden font-bold text-[24px] text-[#0D0D0D] mb-4">Manage Token Unlocking</h3>
              <p className="text-[16px] font-semiBold text-black mb-6">
                Provide transparency for your investors and team while streamlining on-chain operations
              </p>
              <ul className="space-y-4 mb-6">
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute top-[3px]" />
                  <span className="ml-8">Track your token unlocking and claiming transaction flow</span>
                </li>
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute top-[3px]" />
                  <span className="ml-8">Track and manage unlocking for specific schedules and token recipients</span>
                </li>
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute top-[3px]" />
                  <span className="ml-8">Monitor your locked token treasury</span>
                </li>
              </ul>
              <Button
                className="rounded-[100px]"
                variant={ButtonVariant.PRIMARY}
                iconRight={<ArrowRight />}
                onClick={handleNavigateToTokenTable}
              >
                Create Your Token Table Now
              </Button>
            </div>
            <div className="mt-10 md:mt-0 flex flex-row justify-center md:justify-end items-center">
              <img className="rounded-xl md:drop-shadow w-[70%] md:w-[400px]" src={Founder2} alt="Fundraising" />
            </div>
          </div>
          <div
            className={classNames(
              'tab-content w-full mt-[20px] md:mt-10 px-0 sm:px-[46px] lg:px-0',
              { '!hidden': tab !== 'management' },
              'flex flex-col md:grid grid-cols-2'
            )}
          >
            <div className="flex flex-col justify-center lg:mr-[40px]">
              <h3 className="block md:hidden font-bold text-[24px] text-[#0D0D0D] mb-4">FutureToken</h3>
              <p className="text-md font-semiBold text-black mb-6">
                Empower token recipients to tokenize the ownership of locked token
              </p>
              <ul className="space-y-4 mb-6">
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute top-[3px]" />
                  <span className="ml-8">Generate FutureTokens for recipients as credentials to claim tokens</span>
                </li>
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute  top-[3px]" />
                  <span className="ml-8">Easily list your FutureToken (NFT) on mainstream marketplaces</span>
                </li>
                <li className="text-md font-medium text-gray-900 flex items-start relative">
                  <CheckCircledIcon className="absolute  top-[3px]" />
                  <span className="ml-8">Enable upfront liquidity to future tokens</span>
                </li>
              </ul>
              <Button
                className="rounded-[100px]"
                variant={ButtonVariant.PRIMARY}
                iconRight={<ArrowRight />}
                onClick={handleNavigateToTokenTable}
              >
                Manage Your Tokens Table Now
              </Button>
            </div>
            <div className="mt-10 md:mt-0 flex flex-row justify-center md:justify-end items-center">
              <img
                className="rounded-xl md:drop-shadow w-[70%] md:w-[450px]"
                src={TokenManagement}
                alt="Token Management"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FoundersSection;
