const TermsOfService = () => {
  return (
    <div className="max-w-screen-xl mx-auto px-4 md:px-[66px] py-12 text-gray-900">
      <div className="text-display-md font-semibold mb-2">TokenTable Terms and Conditions</div>
      <div className="text-gray-500 text-lg mb-12">Last Updated May 01, 2023</div>
      <div className="text-md text-gray-700 mb-12">
        By accessing our website, you are agreeing to be bound by these terms of service, all applicable laws and
        regulations, and agree that you are responsible for compliance with any applicable local laws. Please read these
        Terms of Service (“Agreement” or “Terms”) carefully. This Agreement is between you and Buildblock Tech Pte.
        Ltd., together with its subsidiaries and affiliates, (“EthSign”, “we”, “our” or “us”), operators of the EthSign
        platform (the “Platform”) currently available at ethsign.xyz.
        <br />
        <br />
        BY USING OR ACCESSING THE PLATFORM, OR RECEIVING SERVICES (AS DEFINED BELOW), YOU ARE AGREEING THAT THESE TERMS
        GOVERN YOUR USE OF THE PLATFORM AND RECEIPT OF THE SERVICES, AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE
        AUTHORITY AND CAPACITY TO ENTER INTO THESE TERMS. IF YOU DISAGREE WITH ANY OF THE PROVISIONS OF THESE TERMS, DO
        NOT LOG INTO AND/OR USE THE SITE.
      </div>
      <div className="text-md text-gray-700 mb-12">
        This Agreement hereby incorporates by this reference any additional terms and conditions with respect to the
        Platform or Services, posted by EthSign to the Platform, or otherwise made available to you by EthSign,
        including without limitation, during the creation, acquisition, transfer, or modification of certain Digital
        Asset, our online and/or mobile services, and software provided on or in connection with those Services.
        <br />
        <br />
        If you are an individual accessing or using the Platform and Services on behalf of, or for the benefit of, any
        corporation, partnership, joint venture, association, limited liability company, trust, unincorporated
        organization or other entity or organization with which you are associated (an “Organization”), then you are
        agreeing to this Agreement on behalf of yourself and such Organization, and you represent and warrant that you
        have the legal authority to bind such Organization to this Agreement. References to “you” and “your” in this
        Agreement will refer to both the individual using the Platform or Services and to any such Organization.
        <br />
        <br />
        THIS AGREEMENT CONTAINS A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER THAT IMPACT YOUR RIGHTS ABOUT HOW
        TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.
      </div>
      <div className="text-xl font-semibold mb-4">Description of the Platform and Services</div>
      <div className="text-md text-gray-700 mb-12">
        EthSign offers a Digital Asset management tool for Organizations, which includes, but is not limited to,
        enabling (i) Organizations to distribute Digital Assets to their constituents, (ii) users, including both of the
        distributing Organization and the receiving person or Organization, to track Digital Asset issuances and vesting
        schedules, (ii) distributors of Digital Assets to implement and control transfer restrictions in connection with
        the distribution of Digital Assets, including, but not limited to, vesting schedules and lock-ups, and (iii)
        distributors of Digital Assets to calculate withholding obligations with respect to the distribution of Digital
        Assets (collectively, the “Services”). “Digital Asset” means a digital asset (also known as, “cryptocurrency,”
        “virtual currency,” or “tokens”), such as bitcoin or ether, which is a digital representation of value based on
        (or built on top of) a cryptographic protocol of a computer network (also known as a “blockchain”).
      </div>
      <div className="text-xl font-semibold mb-4">General Agreement</div>
      <div className="text-md text-gray-700 mb-12">
        By accessing or using the Platform and/or Services, you agree that you have read, understood and accept all of
        the terms and conditions contained in this Agreement as well as our Privacy Policy located here:
        https://www.ethsign.xyz/privacy. You also represent that you have the legal authority to accept these Terms on
        behalf of yourself and any party or Organization you represent in connection with your use of the Platform and
        Services. If you are an individual who is entering into these Terms on behalf of an entity, you represent and
        warrant that you have the power to bind that entity, and you hereby agree on that entity&apos;s behalf to be
        bound by these Terms, with the terms “you”, and “your” applying to you, that entity, and other users accessing
        the Platform and/or Services on behalf of that entity.
        <br />
        <br />
        We may, in our sole and absolute discretion, without liability to you or any third-party, refuse to let you use
        the Platform and/or one or more of the Services. Such actions may be taken as a result of a number of factors,
        including without limitation legal/regulatory requirements or demand, our discretion, or your violation of the
        terms of this Agreement. We may also temporarily suspend your access to the Platform and/or Services if a
        technical problem so requires.
      </div>
      <div className="text-xl font-semibold mb-4">Purchases and Payments</div>
      <div className="text-md text-gray-700 mb-12">
        The Platform may contain the option for you to purchase certain of EthSign&apos;s products and Services. The
        applicable fees (and any applicable discounts, if available), license or subscription period, renewal
        opportunities, and permitted payment methods (e.g., credit or debit) will be specified on the Platform. All
        purchases are final and there will be no refunds, credits, or cancellations except as otherwise expressly
        disclosed at the time of purchase, indicated by EthSign in writing, or as may be required under applicable law.
        All transactions are void where prohibited by law. EthSign may request further information from you in order to
        confirm the order and method of payment. EthSign reserves the right to terminate or suspend access to the
        Platform or any related license, subscription, product, or service if you fail to pay any amounts when due. You
        shall reimburse EthSign for all reasonable costs incurred (including reasonable attorney&apos;s fees) in
        collecting past-due amounts. Unless otherwise specified herein, all payment obligations with respect to amounts
        due to EthSign in connection with the Content shall survive the expiration or termination of these Terms for any
        reason.
        <br />
        <br />
        Upon placing an order, you agree to pay using the payment methods indicated and grant authorization to EthSign
        and/or the applicable third party payment-processor to charge your selected payment methods. EthSign and/or the
        applicable third-party payment processor shall charge, and you shall be responsible for, all taxes, tariffs,
        levies, or duties applicable to your payment. Unless otherwise expressly indicated at the time of purchase, all
        transactions listed through the Platform are denominated in U.S. dollars. You are responsible for: (i) the
        accuracy of all credit and debit card information or other payment method information that you provide to us;
        and (ii) maintaining the confidentiality and security of your account information, including without limitation
        with respect to payment methods. You should not disclose your payment information to anyone. If your account
        information is lost or stolen, anyone who obtains possession of either could utilize the payment methods
        associated with your account. You are responsible for all transactions on your account, including unauthorized
        transactions.
        <br />
        <br />
        To the extent any fees due and payable to EthSign are paid in Digital Assets, such fees shall be denominated in
        such Digital Asset or, to the extent such fees are denominated in USD, the fair market value of a Digital Asset
        used to pay a fee shall be determined by EthSign in its reasonable discretion.
      </div>
      <div className="text-xl font-semibold mb-4">User Accounts</div>
      <div className="text-md text-gray-700 mb-12">
        While you may always browse public-facing portions of the Platform without registering for an account with us,
        in order to access certain portions of the Platform or Services, EthSign may require you to register an account
        with us (an “Account”).
        <br />
        <br />
        You are responsible for the security of your Account and are fully responsible for all activities that occur
        through the use of your Account. You agree to notify us immediately at support@ethsign.xyz if you suspect or
        know of any unauthorized use of your log-in credentials or any other breach of security with respect to your
        Account. We will not be liable for any loss or damage arising from unauthorized use of your credentials. You may
        be required to create and provide separate log-in credentials to access third-party services.
        <br />
        <br />
        To access the Platform or some of the features it offers, including the Services, you may be required to provide
        registration details or other information, including personal data. You must ensure that any information you
        provide on the Platform is accurate, up-to-date, and complete. You agree that any information you provide to
        EthSign in order to create an Account to use the Platform, including, but not limited to, using any features on
        the Platform, is governed by our Privacy Policy, and you consent to any actions that we may take with respect to
        your information consistent with our Privacy Policy.
        <br />
        <br />
        We reserve the right to disallow, cancel, remove, or reassign certain usernames and other information associated
        with an Account in appropriate circumstances, as determined by us in our sole and absolute discretion, and may,
        with or without prior notice, suspend or terminate your Account if activities occur on your Account which would
        or might constitute a violation of this Agreement, cause damage to or impair the Platform or Services, infringe
        or violate any third-party rights, damage or bring into disrepute the reputation of EthSign, or violate
        applicable law. These determinations will be made in EthSign&apos;s sole and absolute discretion. If messages
        sent to the email address you provide are returned as undeliverable, then we may terminate your Account
        immediately without notice to you and without any liability to you or any third-party.
      </div>
      <div className="text-xl font-semibold mb-4">Wallets, Ownership and Control of Digital Assets</div>
      <div className="text-md text-gray-700 mb-12">
        In addition to creating an Account, you may be required to connect a Digital Asset wallet (a “Wallet”) to your
        account in order to enable some features of the Platform. Any failure to connect your Wallet to your Account may
        result in you not being able to use the certain features of the Platform.
        <br />
        <br />
        You acknowledge and agree that EthSign does not control or have access to your Wallet, and EthSign is unable to
        exert any control over your Wallet.
        <br />
        <br />
        In the event EthSign creates software to hold your Digital Asset (a “Smart Contract”) for the purpose of
        enabling certain features of the Platform, you acknowledge and agree that EthSign does not retain any access and
        / or control over such Smart Contract. Any control over a Smart Contract shall be vested in the user that the
        user requests the creation of the Smart Contract from EthSign. EthSign shall not have any control over such
        Smart Contracts, nor will EthSign have the ability to reverse any transactions associated with the Smart
        Contracts.
        <br />
        <br />
        We are not an insured financial institution. EthSign does not know or have access to the private keys to the
        Wallets you connect to the Platform. You are exclusively responsible for maintaining the confidentiality and
        security of your Wallets, Accounts and / or devices you use to access the Platform. You are also responsible for
        maintaining the security of the Wallets you utilize in connection with the Platform.
        <br />
        <br />
        Title to your Digital Assets shall at all times remain with you and shall not transfer to EthSign. As the owner
        of Digital Assets, you shall bear all risk related to the Digital Assets in your Account. EthSign shall have no
        liability for Digital Asset value fluctuations. None of the Digital Assets are the property of, or shall or may
        be loaned to, EthSign; and EthSign does not represent or treat any Digital Assets in your Account as belonging
        to EthSign. EthSign may not grant a security interest in the Digital Assets held in your Account. Except as
        required by a facially valid court order, or except as provided herein, EthSign will not sell, transfer, loan,
        hypothecate, or otherwise alienate Digital Assets unless instructed by you. You control the Digital Assets held
        in your EthSign account
      </div>
      <div className="text-xl font-semibold mb-4">Security and Accuracy of your Account and Wallet</div>
      <div className="text-md text-gray-700 mb-12">
        You are responsible for maintaining the confidentiality and security of your Accounts, Wallets or devices you
        use to access the Platform and Services. You are also responsible for ensuring that no unauthorized person has
        access to any device that you utilize in connection with the Platform and Services. We will not be liable for
        any loss or damage arising from your failure to protect your Account or Wallet.
        <br />
        <br />
        It is your sole responsibility to provide accurate information for creation and maintenance of your Account. You
        are solely responsible for ensuring the accuracy and completeness of all information and materials that you
        provide to use in connection with your use of the Platform or Services. You represent and warrant that (i) all
        such information and materials are true, accurate, and complete in all respects, comply with applicable law and
        do not violate or infringe any third-party rights, and (ii) you will immediately notify us about, and correct,
        and inaccuracy in any such materials or information.
      </div>
      <div className="text-xl font-semibold mb-4">Restricted Jurisdictions</div>
      <div className="text-md text-gray-700 mb-12">
        The Platform and Services are subject to all applicable export control restrictions, and, by using the Platform
        and/or Services, you represent that your actions are not in violation of such export control restrictions.
        Without limiting the foregoing, you may not use the Platform or Services if you are: (i) a resident, national or
        agent of Crimea, Cuba, Donetsk, Iran, Luhansk, North Korea, Syria or any other country or region to which the
        United States embargoes goods or imposes similar sanctions (“Restricted Jurisdictions”); (ii) a member of any
        sanctions list or equivalent maintained by the United States government (“Restricted Persons”); or (iv) you
        intend to transact with any Restricted Jurisdictions or Restricted Persons.
      </div>
      <div className="text-xl font-semibold mb-4">Submissions</div>
      <div className="text-md text-gray-700 mb-12">
        Certain features of the Platform may permit you to upload, post, display, transmit, or otherwise provide certain
        requested information, content, links, files, or other materials to the Platform as part of messaging, review,
        comment, discussion board, or similar functionality on the Platform, and/or as part of a survey, questionnaire,
        promotion, or request for feedback or input regarding the Platform (each a “Submission” and collectively
        “Submissions”).
        <br />
        <br />
        You hereby grant to EthSign a royalty-free, fully transferable, fully sublicensable license to use, display,
        copy, perform, reproduce, modify, record, distribute, and create derivative works of Submissions in connection
        with: (i) EthSign&apos;s operation of the Platform and its features and functionalities; (ii) EthSign&apos;s
        development, promotion, and implementation of its products, services, programs, and events; and (iii)
        EthSign&apos;s research, development, and other business operations.
        <br />
        <br />
        In addition, you agree that you will not upload, post, display, or transmit any Submission(s) that:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>
            Is illegal, defamatory, deceptive, fraudulent, discriminatory, tortious, obscene, pornographic, or otherwise
            objectionable;
          </li>
          <li>Infringes, misappropriates, or otherwise violates the personal or proprietary rights of others;</li>
          <li>
            Contains any virus, malware, worm, trojan horse, disabling device, or any other harmful or malicious script,
            code, or tool;
          </li>
          <li>
            Impersonates any person or entity or falsely states or otherwise misrepresents Your affiliation with a
            person or entity;
          </li>
          <li>Contains unsolicited communications, promotions, or advertisements, or spam;</li>
          <li>
            Harms, harasses, threatens, or violates the rights of any third party, or promotes, provokes, or incites
            violence;
          </li>
          <li>
            Constitutes false advertising, false endorsement, or is otherwise false, misleading, or likely to cause
            consumer confusion; or
          </li>
          <li>Manipulates data or identifiers in order to misrepresent or disguise the origin of the Submission.</li>
        </ul>
        <br />
        EthSign may screen, review, edit, moderate, or monitor your Submissions from time to time at its discretion, but
        has no obligation to do so. In any event, EthSign is not responsible to you under these Terms for your or any
        other user&apos;s Submissions, and shall have no liability or responsibility for the quality, content, accuracy,
        legality, or effectiveness of any Submissions. You acknowledge and agree that EthSign shall have no obligation
        of confidentiality whatsoever with respect to your Submissions.
        <br />
        <br />
        By uploading, posting, displaying, transmitting, or otherwise providing a Submission to the Platform, you
        represent and warrant that: (i) you possess all legal rights required to upload, post, display, and/or transmit
        each Submission and permit EthSign to use such Submission as set forth herein (including without limitation any
        necessary third-party license rights or required consents under applicable law); (ii) each Submission is in full
        compliance with all applicable laws and regulations; and (iii) your Submissions do not infringe, misappropriate,
        or otherwise violate the personal or proprietary rights of any third party.
      </div>
      <div className="text-xl font-semibold mb-4">Acceptable Use</div>
      <div className="text-md text-gray-700 mb-12">
        In connection with your use of the Platform and/or Services, you will not:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>
            Violate or assist any party in violating any law, statute, ordinance, regulation or any rule of any
            self-regulatory or similar organization of which you are or are required to be a member through your use of
            the Platform or Services;
          </li>
          <li>
            Repost, delete, or alter any content or material that EthSign makes available on the Platform and/or
            Services;
          </li>
          <li>
            Infringe upon our or any third-party&apos;s copyright, patent, trademark, or other intellectual property
            rights;
          </li>
          <li>Restrict or inhibit any other person from legal use of the Platform or Services;</li>
          <li>
            Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare,
            distribute or otherwise exploit any portion of (or any use of) the Platform or Services except as expressly
            authorized herein, without EthSign&apos;s express prior written consent;
          </li>
          <li>
            Reverse engineer, decipher, decompile or disassemble any portion of the Platform, except to the extent such
            restriction is expressly prohibited by applicable law;
          </li>
          <li>Remove any copyright, trademark or other proprietary rights notice from the Platform or Services;</li>
          <li>
            Frame or mirror any portion of the Platform or Services, or otherwise incorporate any portion of the
            Platform into any product or service, without EthSign&apos;s express prior written consent;
          </li>
          <li>Restrict, discourage or inhibit any person from using the Platform or Services;</li>
          <li>Systematically download and store content from the Platform or Services;</li>
          <li>
            Use any robot, spider, Platform search/retrieval application or other manual or automatic device to
            retrieve, index, “scrape,” “data mine”, or otherwise gather content on the Platform or Services (including
            Submissions), or reproduce or circumvent the navigational structure or presentation of the Platform, without
            EthSign&apos;s express prior written consent. Notwithstanding the foregoing, and subject to compliance with
            applicable law and any instructions posted in the robots.txt file located in the Platform&apos;s root
            directory, EthSign grants to the operators of public search engines permission to use spiders to copy
            materials from the Platform for the sole purpose of (and solely to the extent necessary for) creating
            publicly available, searchable indices of such materials, but not caches or archives of such materials.
            EthSign reserves the right to revoke such permission either generally or in specific cases, at any time and
            without notice.
          </li>
          <li>
            Take any action that imposes an unreasonable or disproportionately large load on our infrastructure or that
            of our nodes, or detrimentally interfere with, intercept, or expropriate any system, data or information;
          </li>
          <li>
            Post, transmit or otherwise make available through or in connection with the Platform or Services any virus,
            worm, trojan horse, easter egg, time bomb, spyware, ransomware, malware, or other computer code, file or
            program that is or is potentially harmful or invasive or intended to damage or hijack the operation of, or
            to monitor the use of, any hardware, software or equipment.
          </li>
          <li>
            Otherwise attempt to gain unauthorized access to or use of the Platform, other Accounts, or computer systems
            connected to the Platform; or
          </li>
          <li>
            Engage in any behavior which violates this Agreement or is otherwise deemed unacceptable by us in our sole
            discretion.
          </li>
        </ul>
      </div>
      <div className="text-xl font-semibold mb-4">Reservation of Rights</div>
      <div className="text-md text-gray-700 mb-12">
        EthSign reserves the right to bar any transactions on the Platform, for or with, any user with or without cause,
        at any time, subject to any limitations imposed by applicable law. In lieu of refusing access to the Platform or
        Services, EthSign may, in its sole and absolute discretion, perform due diligence. You may be subject to due
        diligence procedures in your use of the Platform or Services. If you decline to provide requested information or
        otherwise do not reply timely or substantively with the documentation or data requested, EthSign has the
        absolute discretion to immediately suspend or terminate your Account.
      </div>
      <div className="text-xl font-semibold mb-4">Intellectual Property Rights</div>
      <div className="text-md text-gray-700 mb-12">
        The Platform and its entire contents, features, and functionality (including but not limited to all Services,
        information, software, text, displays, images, video, and audio, and the design, selection, and arrangement
        thereof and with the exception of User Content), are owned by EthSign, its licensors, or other providers of such
        material and are protected by Singapore and international copyright, trademark, patent, trade secret, and other
        intellectual property or proprietary rights laws. EthSign grants you a revocable, non-exclusive,
        non-transferable, non-sublicensable, royalty-free and worldwide right and license to access and use the Platform
        or Services solely in strict compliance with the provisions of this Agreement and as permitted by the
        functionalities of the Platform or Services available to you.
        <br />
        <br />
        EthSign’s name and all related names, logos, product and service names, designs, and slogans are trademarks of
        EthSign, its Affiliates, or licensors (collectively, “Associates”). You must not use such marks without the
        prior written permission of EthSign. All other names, logos, product and service names, designs, and slogans on
        this Platform are the trademarks of their respective owners.
        <br />
        <br />
        EthSign reserves all rights not expressly granted to you in this section. Accordingly, nothing in this Agreement
        or on the Platform or through the Services will be construed as granting to you, by implication, estoppel or
        otherwise, any additional license rights in and to the Platform or any EthSign intellectual property located or
        displayed on or within the Platform or Services.
      </div>
      <div className="text-xl font-semibold mb-4">Indemnification and Release</div>
      <div className="text-md text-gray-700 mb-12">
        EthSign and its past, present and future partners, parents, subsidiaries, affiliates and joint venturers, as
        well as all of their respective past, present and future its officers, directors, agents, employees,
        representatives, attorneys, predecessors, successors and assigns, whether acting in the course and scope of
        employment, and all persons acting by, through, under, or in concert with any of them (collectively, the
        “EthSign Affiliates”), are not liable for any damages that may arise out of or in connection with your use of
        the Platform or Services. This includes, but is not limited to, claims, applications, injuries, delays, direct
        loss, loss of business or profits, business interruption costs, loss of goodwill or business profits, loss of
        Digital Assets, damage caused by your computer, computer software, systems and programs, and the data thereon or
        any other direct or indirect, consequential and incidental damages. In addition, the EthSign Affiliates are not
        liable for any losses incurred, either directly or indirectly through your use of the Platform or any of its
        functions and features (collectively, all of the foregoing items shall be referred to herein as “losses”). The
        EthSign Affiliates are hereby released by you from liability for any and all losses. These limitations of
        liability apply whether the liability or losses are based on negligence, contract, tort, or any other basis,
        even if the EthSign Affiliates had been advised or should have known of the possibility of such losses.
        <br />
        <br />
        You agree to indemnify and hold the EthSign Affiliates harmless from any claim or losses (including attorneys’
        fees and any losses, fines, fees, or penalties imposed by any regulatory authority) arising out of your
        negligence, breach of this Agreement, or your violation of any law or regulation.
        <br />
        <br />
        If you have a dispute with one or more user, distributors of Digital Assets on the Platform or other
        third-parties, you release the EthSign Affiliates from any and all claims, demands, and damages (actual and
        consequential) of every kind and nature arising out of or in any way connected with such disputes.
      </div>
      <div className="text-xl font-semibold mb-4">Limitation of Liability</div>
      <div className="text-md text-gray-700 mb-12">
        TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL ETHSIGN, ITS ASSOCIATES, OR THEIR LICENSORS, SERVICE
        PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
        ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE PLATFORM, ANY WEBSITES LINKED TO IT, ANY
        CONTENT ON THE PLATFORM OR SUCH OTHER WEBSITES, OR THE SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
        INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
        SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS
        OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
        OR OTHERWISE, EVEN IF FORESEEABLE. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS, NOTHING IN THESE
        TERMS EXCLUDES OR LIMITS LIABILITY FOR FRAUD, FRAUDULENT MISREPRESENTATION, OR FOR DEATH OR PERSONAL INJURY
        CAUSED BY GROSS NEGLIGENCE.
        <br />
        <br />
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
      </div>
      <div className="text-xl font-semibold mb-4">Disclaimer of Warranties</div>
      <div className="text-md text-gray-700 mb-12">
        YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT YOUR ACCESS TO AND USE OF THE PLATFORM, SERVICES, AND ANY CONTENT IS AT
        YOUR SOLE RISK. OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER ETHSIGN NOR ITS ASSOCIATES MAKE ANY
        WARRANTY, EITHER EXPRESS OR IMPLIED, ABOUT THE PLATFORM OR SERVICES. THE PLATFORM AND SERVICES ARE PROVIDED ON
        AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
        ETHSIGN NOR ITS ASSOCIATES MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
        RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE PLATFORM OR SERVICES. WITHOUT LIMITING THE FOREGOING,
        ETHSIGN AND ITS ASSOCIATES DO NOT REPRESENT OR WARRANT THAT THE PLATFORM, ITS CONTENT, OR ANY SERVICES OR ITEMS
        OBTAINED THROUGH THE PLATFORM WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
        CORRECTED, THAT OUR PLATFORM, SERVICES, OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER
        HARMFUL COMPONENTS, OR THAT THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL OTHERWISE
        MEET YOUR NEEDS OR EXPECTATIONS.
        <br />
        <br />
        THERE MAY BE INADVERTENT TECHNICAL OR FACTUAL INACCURACIES AND TYPOGRAPHICAL ERRORS IN INFORMATION OR MATERIALS
        ON THE PLATFORM OR SERVICES, AND ETHSIGN MAKES NO WARRANTIES REGARDING THE ACCURACY, COMPLETENESS OR TIMELINESS
        OF SUCH INFORMATION OR MATERIALS. ETHSIGN PROVIDES NO GUARANTEES AGAINST THE POSSIBILITY OF DELETION,
        MIS-DELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER DATA. ETHSIGN EXPRESSLY
        DISCLAIMS ALL LIABILITY FOR ERRORS OR OMISSIONS IN, OR THE MISUSE OR MISINTERPRETATION OF, ANY INFORMATION OR
        MATERIALS CONTAINED ON THE PLATFORM OR SERVICES.
        <br />
        <br />
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, ETHSIGN AND ITS ASSOCIATES ALSO DISCLAIM ALL WARRANTIES OF ANY KIND,
        WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        <br />
        <br />
        SOME STATES OR JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO SOME DISCLAIMERS IN THIS
        SECTION MAY NOT APPLY TO YOU.
        <br />
        <br />
        We do not guarantee continuous, uninterrupted or secure access to any part of the Platform or Services, and
        operation of our Platform and Services may be interfered with by numerous factors outside of our control,
        including regulatory reasons and issues with the EthSign Platform. We will make reasonable efforts to ensure
        that transactions are processed in a timely manner, but we make no representations or warranties regarding the
        amount of time needed to complete transactions.
      </div>
      <div className="text-xl font-semibold mb-4">Representations & Warranties</div>
      <div className="text-md text-gray-700 mb-12">
        You represent and warrant to EthSign each time you use the Platform or Services as follows:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>That, if you are an individual user of the Platform or Services, you are 18 years of age or older;</li>
          <li>
            That, if you are using the Platform or Services on behalf of an Organization (i) such Organization is duly
            organized and validly existing under applicable law of the jurisdiction of its organization; and (ii) you
            are duly authorized by such Organization to act on its behalf;
          </li>
          <li>That you are not subject to a Restricted Jurisdiction;</li>
          <li>That you are not otherwise prohibited by applicable law from using the Platform and Services;</li>
          <li>That you understand the risks associated with using the Platform and Services;</li>
          <li>
            That you have had the opportunity to seek legal, accounting, taxation and other professional advice
            regarding this Agreement, Platform, and Services;
          </li>
          <li>
            That you will not use the Platform or Services in order to conceal or disguise the origin or nature of
            proceeds of crime or terrorist financing, or to further, any breach of applicable law, or to deal in any
            unlawful property, funds, or proceeds;
          </li>
          <li>That you will not falsify any registration details provided to EthSign;</li>
          <li>
            That neither you nor any of your affiliates has directly or indirectly offered, promised, given, or
            authorized any payment, or offered, promised, given, or authorized the giving of anything else of value,
            including, but not limited to, any Digital Asset, to a government official or individual employed by another
            entity in the private sector in violation of any applicable law;
          </li>
          <li>That you consent to any and all tax and information reporting under applicable law;</li>
          <li>
            That neither you nor any of your affiliates will use the Services directly or indirectly (i) on behalf of or
            for the benefit of any person subject to the jurisdiction of a Restricted Jurisdiction; (ii) in violation of
            or as prohibited, restricted, or penalized under applicable law; or (iii) in any way that would violate, be
            inconsistent with, penalized under, or cause the omission of filing of any report required under applicable
            law.
          </li>
          <li>
            That you will not falsify or materially omit any information or provide misleading or inaccurate information
            requested by EthSign in the course of, directly or indirectly relating to, or arising from your activities
            on the Platform, including but not limited to, registration or other due diligence process, and that if any
            information provided to EthSign becomes incorrect or outdated, you will promptly provide corrected
            information to EthSign; and
          </li>
          <li>
            That you will accurately and promptly inform EthSign if you know or have reason to know whether any of the
            foregoing representations or warranties no longer is correct or becomes incorrect.
          </li>
        </ul>
      </div>
      <div className="text-xl font-semibold mb-4">Data Protection and Security</div>
      <div className="text-md text-gray-700 mb-12">
        You acknowledge that EthSign may process personal data in relation to you (if you are an individual), and
        personal data that you have provided or in the future provide to us in relation to your employees and other
        associated or other individuals, in connection with this Agreement or use of the Platform or Services. You
        represent and warrant that any personal data relating to individuals other than yourself was or will be made in
        accordance with all applicable data protection and data privacy laws, and that data is accurate at the time of
        disclosure. You further represent and warrant that before providing any such personal data to us, you have read
        and understood our Privacy Policy, and, in the case of personal data relating to an individual other than
        yourself, have (or will at the time of disclosure have) provided a copy of that Privacy Policy, to that
        individual.
        <br />
        <br />
        If you suspect that your Account or any of your security details have been compromised, or if you become aware
        of any fraud or attempted fraud or any other security incident (including a cyber-security attack) affecting you
        and/or EthSign, you must notify EthSign as soon as possible by email and provide accurate information throughout
        the duration of the incident or breach.
      </div>
      <div className="text-xl font-semibold mb-4">Privacy</div>
      <div className="text-md text-gray-700 mb-12">
        All information we collect on the Platform and Services is subject to our Privacy Policy. By using the Platform
        and/or Services, you consent to all actions taken by us with respect to your information in compliance with our
        Privacy Policy.
        <br />
        <br />
        Our Privacy Policy is a part of this Agreement. Please review our Privacy Policy, which also governs the
        Platform and informs users of our data collection practices.
        <br />
        <br />
        Please note that it is our policy to comply with all facially valid subpoenas, court orders or binding orders
        issued to us by law enforcement agencies and other government authorities. This may affect your access to your
        account and our Services. We are not responsible for any losses, whether direct or indirect, that you may incur
        as a result of our compliance with applicable law, the guidance or direction of any regulatory authority or
        government authority, or any writ of attachment, lien, levy, subpoena, warrant, or other legal order.
      </div>
      <div className="text-xl font-semibold mb-4">Comments and Questions</div>
      <div className="text-md text-gray-700 mb-12">
        If you choose to provide us with input or suggestions regarding problems with or proposed modifications or
        improvements to the Platform or Services (“Feedback”), then you grant to us a non-exclusive, royalty-free,
        perpetual, irrevocable, transferable, sublicensable, and global right to use and exploit the Feedback in any
        manner and for any purpose without any restriction, credit, attribution, or fees due to you.
      </div>
      <div className="text-xl font-semibold mb-4">Governing Law; Venue and Arbitration</div>
      <div className="text-md text-gray-700 mb-12">
        Any controversy or claim arising out of or relating to these Terms, or the breach hereof, shall be settled by
        arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration
        Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction
        thereof. Arbitration prevents you from suing in court or from having a jury trial.
        <br />
        <br />
        In addition, you agree:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>To attempt informal resolution prior to any demand for arbitration;</li>
          <li>That any arbitration will occur in the Singapore;</li>
          <li>
            That any arbitration will be conducted confidentially by a single arbitrator, selected by a third-party
            arbitration forum;
          </li>
          <li>
            That you cannot litigate any disputes arising from your use or access to the Platform through class action
            lawsuits;
          </li>
          <li>
            That the country of Singapore has exclusive jurisdiction over any appeals of an arbitration award and over
            any suit between the parties not subject to arbitration;
          </li>
          <li>
            That the arbitrator has the authority to grant any remedy that would otherwise be available in court; and
          </li>
          <li>
            That the parties shall split the costs and expenses of any arbitration and bear their own legal costs and
            expenses.
          </li>
        </ul>
        <br />
        Any dispute between the parties will be governed by these Terms and the laws of Singapore, without giving effect
        to any conflict of laws principles that may provide for the application of the law of another jurisdiction.
        <br />
        <br />
        You will only be permitted to pursue claims against us on an individual basis, not as a plaintiff or class
        member in any class or representative action or proceeding, and you will only be permitted to seek relief
        (including monetary, injunctive, and declaratory relief) on an individual basis.
      </div>
      <div className="text-xl font-semibold mb-4">Class Action Waiver</div>
      <div className="text-md text-gray-700 mb-12">
        BY USING THIS SITE AND AGREEING TO THESE TERMS, YOU HEREBY WILLINGLY, EXPRESSLY, AND KNOWINGLY WAIVE ALL RIGHT
        TO BRING OR PARTICIPATE IN ANY CLASS-ACTION LAWSUIT, CLASS-WIDE ARBITRATION, OR PRIVATE ATTORNEY-GENERAL ACTION
        BROUGHT UNDER OR IN CONNECTION WITH THIS AGREEMENT OR YOUR USE OF THE PLATFORM. YOU MAY NOT BRING ANY CLAIM,
        SUIT, OR OTHER PROCEEDING TO ENFORCE THESE TERMS AS THE MEMBER OF ANY CLASS OR AS PART OF ANY SIMILAR COLLECTIVE
        OR CONSOLIDATED ACTION.
      </div>
      <div className="text-xl font-semibold mb-4">Termination and Suspension</div>
      <div className="text-md text-gray-700 mb-12">
        These Terms are effective until terminated by us. EthSign, in its sole discretion, has the right to terminate
        your access to the Platform or Services, or any part thereof, immediately at any time. We reserve the right, in
        our sole and absolute discretion, to restrict, suspend, or terminate your access to all or any part of the
        Platform or Service, or to terminate this Agreement at any time without prior notice or liability if you breach
        any provision of this Agreement or violate the rights of any third-party on or through the Platform. We reserve
        the right to change, suspend, or discontinue all or any part of the Platform at any time without prior notice or
        liability.
        <br />
        <br />
        EthSign is not liable to you or any third-party for termination of your access to the Platform or Services, or
        any part thereof. If you object to any of the requirements, statements, or obligations of these Terms, or any
        subsequent modifications thereto, or become dissatisfied with the Platform or Services in any way, your only
        recourse is to immediately discontinue use of the Platform and/or Services. Upon termination of these Terms, you
        will cease all use of the Platform and Services.
        <br />
        <br />
        You may terminate this Agreement by sending written notification to us at support@tokentable.xyz and terminating
        your use of the Platform and Services.
      </div>
      <div className="text-xl font-semibold mb-4">Relationship of the Parties</div>
      <div className="text-md text-gray-700 mb-12">
        You agree that no joint venture, partnership, employment or agency relationship exists between you and EthSign
        as a result of this Agreement or use of the Platform or Services.
      </div>
      <div className="text-xl font-semibold mb-4">Force Majeure</div>
      <div className="text-md text-gray-700 mb-12">
        We shall have no liability for any failure or delay resulting from any abnormal or unforeseeable circumstances
        outside our reasonable control, the consequences of which would have been unavoidable despite all efforts to the
        contrary, including without limitation governmental action or acts of terrorism, war, earthquake, fire, flood,
        or other acts of God, labor conditions, delays or failures caused by problems with another system or network,
        mechanical breakdown or data-processing failures or where we are bound by other legal obligations.
      </div>
      <div className="text-xl font-semibold mb-4">Third-Party Disputes</div>
      <div className="text-md text-gray-700 mb-12">
        ANY DISPUTE YOU HAVE WITH ANY THIRD-PARTY, INCLUDING, BUT NOT LIMITED TO, OTHER USERS, IN CONNECTION WITH YOUR
        USE OF THE PLATFORM OR SERVICES IS DIRECTLY BETWEEN YOU AND SUCH THIRD-PARTY. ACCORDINGLY, TO THE FULLEST EXTENT
        PERMITTED BY LAW, YOU IRREVOCABLY RELEASE ETHSIGN AND ITS ASSOCIATES FROM ANY AND ALL CLAIMS, DEMANDS AND
        DAMAGES (DIRECT, INDIRECT, AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN
        ANY WAY CONNECTED WITH SUCH DISPUTES.
      </div>
      <div className="text-xl font-semibold mb-4">Assignments</div>
      <div className="text-md text-gray-700 mb-12">
        This Agreement will inure to the benefit of our successors and assigns. You may not assign this Agreement or any
        of the rights or licenses granted hereunder without the prior express written consent of EthSign. EthSign may
        assign this Agreement, including all its rights hereunder, without restriction.
      </div>
      <div className="text-xl font-semibold mb-4">Amendments</div>
      <div className="text-md text-gray-700 mb-12">
        We reserve the right to change this Agreement at any time upon notice. We may give notice by posting the updated
        Agreement on the Platform or by any other reasonable means. You can review the most current version of this
        Agreement at any time. This Agreement in effect at the time of your use of the Platform apply. Updated versions
        of the Agreement are binding on you with respect to your use of the Platform on or after the date indicated in
        the updated Agreement. If you do not agree to the updated Agreement, you must stop using the Platform. Your
        continued use of the Platform after the date of the updated Agreement will constitute your acceptance of the
        updated Agreement.
      </div>
      <div className="text-xl font-semibold mb-4">Survival</div>
      <div className="text-md text-gray-700 mb-12">
        The respective indemnities, representations, warranties and agreements of the parties hereto or made by or on
        behalf of the parties hereto pursuant to this Agreement will survive the termination of this Agreement
        indefinitely and will remain in full force and effect and all defined terms used therein will survive the
        termination of this Agreement indefinitely.
      </div>
      <div className="text-xl font-semibold mb-4">Waiver and Conflict</div>
      <div className="text-md text-gray-700 mb-12">
        No waiver of by EthSign of any term or condition set out in these Terms will be deemed a further or continuing
        waiver of such term or condition or a waiver of any other term or condition, and any failure of EthSign to
        assert a right or provision under these Terms will not constitute a waiver of such right or provision.
        <br />
        <br />
        If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid,
        illegal or unenforceable for any reason, such provision will be eliminated or limited to the minimum extent such
        that the remaining provisions of the Terms will continue in full force and effect.
      </div>
      <div className="text-xl font-semibold mb-4">Entire Agreement</div>
      <div className="text-md text-gray-700 mb-12">
        This Agreement and the Privacy Policy constitute the sole and entire agreement between you and EthSign with
        respect to the Platform and supersede all prior and contemporaneous understandings, agreements, representations
        and warranties, both written and oral, with respect to the Platform. This Agreement does not, and will not be
        construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee
        relationship between you and EthSign.
      </div>
      <div className="text-xl font-semibold mb-4">Third-Party Rights</div>
      <div className="text-md text-gray-700 mb-12">
        This Agreement is not intended and shall not be construed to create any rights or remedies in any parties other
        than you and us and any affiliates which each shall be a third-party beneficiary of this Agreement, and no other
        person shall assert any rights as a third-party beneficiary hereunder.
      </div>
    </div>
  );
};

export default TermsOfService;
