import { Title } from 'components/Title';
import PictureOne from '../../assets/images/Investors/investor-1.png';
import PictureTwo from '../../assets/images/Investors/investor-2.png';
import PictureThree from '../../assets/images/Investors/investor-3.png';

const InvestorsSection = () => {
  return (
    <section className="bg-white md:bg-transparent pt-[64px] md:pt-[80px] pb-[64px] md:pb-0 relative">
      <p className="text-[16px] md:text-md text-text font-semiBold text-center">Never lose sight of your portfolio</p>
      <h2 className="text-[32px] md:text-[38px] font-bold text-black text-center mt-[16px]">
        TokenTable for <Title>Investors</Title>
      </h2>
      <div className="flex flex-col items-center max-w-screen-xl mx-auto px-5 md:px-6 lg:px-20">
        <div className="flex items-center flex-col-reverse mt-0 md:mt-10 md:flex-row h-auto md:h-[420px]">
          <div className="mt-0 md:mt-10 sm:w-[520px]">
            <h3 className="text-[28px] text-center text-black font-bold md:text-left">Claim Unlocked Tokens</h3>
            <p className="text-center md:text-left text-[18px] font-medium text-black mt-2">
              Effortless to be an investor, no need to concern about rug or delay of your token allocations, all
              distributions are guaranteed to be executed by smart contract, only thing you&apos;re going to do is claim
              it, and no worried to miss token unlocks, you will be promptly notified via email of the your token unlock
              events.
            </p>
          </div>
          <div className="h-full ml-0 md:ml-16" data-aos="fade-up">
            <img
              className="object-contain rounded-xl h-auto md:h-[420px] md:drop-shadow"
              src={PictureOne}
              alt="Claim Unlocked Tokens"
            />
          </div>
        </div>

        <div className="flex items-center flex-col mt-10 md:flex-row sm:h-[420px]">
          <div className="h-full ml-0 sm:mr-16" data-aos="fade-up">
            <img
              className="object-contain rounded-xl h-[250px] md:h-[420px] md:drop-shadow"
              src={PictureTwo}
              alt="Track Transactions"
            />
          </div>
          <div className="mt-10 ml-0 md:ml-[48px] md:mt-0 sm:w-[520px]">
            <h3 className="text-[28px] text-center text-black font-bold md:text-left">Track Transactions</h3>
            <p className="text-center md:text-left text-[18px] font-medium text-black mt-2">
              Instead of spending hours collecting and consolidating transactions from scattered wallets by spreadsheet,
              you can view and track all of your token allocations, releases, claims, and secondary sales of your
              portfolio companies on a single dashboard, and grasp the unlocking progress and timetable through
              unlocking graph.
            </p>
          </div>
        </div>
        <div className="flex items-center flex-col-reverse mt-10 md:flex-row sm:h-[420px]">
          <div className="mt-0 md:mt-10 sm:w-[520px]">
            <h3 className="text-[28px] text-center text-black font-bold sm:text-left">Unleash Capital Efficiency</h3>
            <p className="text-center sm:text-left text-[18px] font-medium text-black mt-2">
              Tokenize ownership of locked assets with future token, enabling early liquidity through easy listing on
              marketplaces, buy/Sell your locked token with vesting schedule with zero slippage and peer to peer. <br />
              Coming soon, stay tuned on our social media.
            </p>
          </div>
          <div className="h-full ml-0 md:ml-16" data-aos="fade-up">
            <img
              className="object-contain rounded-xl h-[250px] md:h-[420px] md:drop-shadow"
              src={PictureThree}
              alt="Claim Your Unlocked Token"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvestorsSection;
