import AuditCompanyImage from 'assets/images/audit.png';
import AuditImage from 'assets/images/security.png';
import { Button, ButtonVariant } from 'components/Button';
import { AUDIT_LINK } from 'utils/constants';
import { navigate } from 'utils/utils';

const SecuritySection = () => {
  return (
    <section className="relative pt-0 md:pt-[132px]">
      <div className="w-full max-w-screen-xl mx-[auto] bg-[#FDF7F3] rounded-[24px] h-auto md:h-[400px] flex flex-col-reverse md:flex-row justify-center md:justify-between items-center px-[20px] md:px-[62px] py-[30px] md:py-[20px]">
        <div>
          <div className="text-center md:text-left text-text font-semiBold text-[16px] mt-6 md:mt-0">
            Enterprise-grade security
          </div>
          <div className="text-center md:text-left font-bold text-black text-[32px] md:text-[40px]">
            Security comes first
          </div>
          <div className="text-center md:text-left font-medium text-black text-[16px] mt-3 max-w-[336px]">
            We work intensively with the most trusted organizations in the space to create risk-assessment frameworks of
            smart contract.
          </div>
          <div className="flex flex-row justify-center md:justify-start items-center mt-4 gap-4 w-full md:w-auto">
            <Button className="rounded-[100px]" variant={ButtonVariant.PRIMARY} onClick={() => navigate(AUDIT_LINK)}>
              Audit Report
            </Button>
            <Button
              className="rounded-[100px] border-black text-black"
              variant={ButtonVariant.OUTLINED}
              onClick={() => navigate('https://docs.tokentable.xyz/developers/v2.5')}
            >
              Techical Docs
            </Button>
          </div>
          <div className="flex flex-row justify-center md:justify-start items-center mt-10 gap-4">
            <div className="font-inter text-gray-600 text-[16px]">Audited by</div>
            <img src={AuditCompanyImage} alt="audit-company" width="90px" height="auto" />
          </div>
        </div>
        <div className="flex justify-center items-center ml-0 md:ml-[98px]">
          <img src={AuditImage} width="auto" className="max-w-[393px]" height="auto" alt="audit-image" />
        </div>
      </div>
    </section>
  );
};

export default SecuritySection;
