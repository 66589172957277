import classNames from 'classnames';
import './LoadingIcon.css';

type Props = {
  className?: string;
  loaderClassName?: string;
};

export const LoadingIcon = ({ className, loaderClassName }: Props) => {
  return (
    <div className={classNames('lds-ring', className)}>
      <div className={loaderClassName}></div>
      <div className={loaderClassName}></div>
    </div>
  );
};
