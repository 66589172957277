import { FC } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { TwitterFeed } from 'types/TwitterFeed';

export interface ITwitterFeedSlide {
  feed: TwitterFeed;
}

export const TwitterFeedSlide: FC<ITwitterFeedSlide> = ({ feed }) => {
  const { link } = feed;

  const getTweetId = (url: string) => {
    const id = url.split('status/')[1];

    return id.split('?')[0];
  };

  return (
    <div className="w-full max-w-[90vw] md:max-w-none">
      <TwitterTweetEmbed
        tweetId={getTweetId(link)}
        options={{ align: 'center', cards: 'hidden', conversation: 'none' }}
      />
    </div>
  );
};
