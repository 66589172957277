export const FeatherIcon = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21 10H7" stroke="#373B46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 6H3" stroke="#373B46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 14H3" stroke="#373B46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 18H7" stroke="#373B46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
