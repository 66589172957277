export const NETWORKS = ['ethereum', 'ropsten', 'bsc', 'avalanche', 'polygon', 'fantom'];

export const MEDIUM_LINK = 'https://medium.com/ethsign';
export const TWITTER_LINK = 'https://twitter.com/tokentable';
export const TELEGRAM_LINK = 'https://t.me/+z04RgODcpZs3NDll';
export const FEATURE_REQUEST_LINK = 'https://form.typeform.com/to/PQrhy6MM';
export const FEEDBACK_LINK = 'https://form.typeform.com/to/i9JTuh1V';
export const DEMO_LINK = 'https://drive.google.com/file/d/1_gBHSJ8IKJWPUlzZXetmXAWToNfexyT1/view';
export const TUTORIAL_LINK = 'https://docs.tokentable.xyz/';
export const ETHSIGN_LINK = 'https://www.ethsign.xyz';
export const BRAND_ASSET_LINK = 'https://drive.google.com/drive/folders/11nMTpBt9B7Kogf321uIPj0KvSXXMGE3a?usp=sharing';
export const PRIVACY_POLICY_LINK =
  'https://docs.google.com/document/d/14Io8i6Qw_qs3k6qKByXRcJaUjrsL_pLsHPzv3_UnRmE/edit?usp=sharing';
export const TOKENTABLE_LINK = 'https://app.tokentable.xyz';
export const STAGING_TOKENTABLE_LINK = 'https://staging.tokentable.xyz';
export const ARKHAM_TOKENTABLE_LINK = `${TOKENTABLE_LINK}/share/arkham`;
export const CYBER_CONNECT_TOKENTABLE_LINK = `${TOKENTABLE_LINK}/share/cyber-connect`;
export const WORLDCOIN_TOKENTABLE_LINK = `${TOKENTABLE_LINK}/share/worldcoin`;
export const REQUEST_DEMO_LINK = 'https://calendly.com/rishi_thomas/product-introduction-call';
export const KARATDAO_TOKENTABLE_LINK = `${STAGING_TOKENTABLE_LINK}/share/KaratDAO`;

export const GITHUB_LINK = 'https://docs.tokentable.xyz/developers/v2/core/unlocker';
export const START_LINK = 'https://app.tokentable.xyz/signin';
export const AUDIT_LINK = 'https://docs.tokentable.xyz/developers/v2/audit-report';
export const DOC_LINK = 'https://docs.tokentable.xyz/';
export const TUTORIALS_LINK = 'https://docs.tokentable.xyz/product/for-founders/setup-your-project';
export const BRAND_ASSETS_LINK = 'https://drive.google.com/drive/folders/11nMTpBt9B7Kogf321uIPj0KvSXXMGE3a?usp=sharing';
export const TROUBLE_SHOOTING_LINK = 'https://form.typeform.com/to/i9JTuh1V';
export const FAQ_LINK = 'https://docs.tokentable.xyz/support/faq';
