const PrivacyPolicy = () => {
  return (
    <div className="max-w-screen-xl mx-auto px-4 md:px-[66px] py-12 text-gray-900">
      <div className="text-display-md font-semibold mb-2">TokenTable Privacy and Data Storage Statement</div>
      <div className="text-gray-500 text-lg mb-2">Last Updated May 01, 2023</div>
      <div className="text-lg mb-12">
        At EthSign TokenTable, we take your privacy seriously. We are committed to protecting any information we may
        collect from you when you visit our website.
      </div>
      <div className="text-md text-gray-700 mb-12">
        This privacy notice for Buildblock Tech Pte Ltd (doing business as TokenTable) (&ldquo;TokenTable,&ldquo;
        &ldquo;we,&ldquo; &ldquo;us,&ldquo; or &ldquo;our&ldquo;), describes how and why we might collect, store, use,
        and/or share (&ldquo;process&ldquo;) your information when you use our services (&ldquo;Services&ldquo;), such
        as when you:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>Visit our website at tokentable.xyz, or any website of ours that links to this privacy notice</li>
          <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>
        <br />
        Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If
        you do not agree with our policies and practices, please do not use our Services. If you still have any
        questions or concerns, please contact us at support@tokentable.xyz.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">1.&nbsp;</span> WHAT INFORMATION DO WE COLLECT?
      </div>
      <div className="text-xl font-semibold mb-4">Personal information you disclose to us</div>
      <div className="text-md text-gray-700 mb-12">
        We collect personal information that you voluntarily provide to us when you register on the Services, express an
        interest in obtaining information about us or our products and Services, when you participate in activities on
        the Services, or otherwise when you contact us.
        <br />
        <br />
        Personal Information Provided by You. The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the products and features you use. The personal
        information we collect may include the following:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>names</li>
          <li>email addresses</li>
          <li>job titles</li>
          <li>contact or authentication data</li>
        </ul>
        <br />
        Sensitive Information. We do not process sensitive information.
        <br />
        <br />
        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of
        any changes to such personal information.
      </div>
      <div className="text-xl font-semibold mb-4">Information automatically collected</div>
      <div className="text-md text-gray-700 mb-12">
        We automatically collect certain information when you visit, use, or navigate the Services. This information
        does not reveal your specific identity (like your name or contact information) but may include device and usage
        information, such as your IP address, browser and device characteristics, operating system, language
        preferences, referring URLs, device name, country, location, information about how and when you use our
        Services, and other technical information. This information is primarily needed to maintain the security and
        operation of our Services, and for our internal analytics and reporting purposes.
        <br />
        <br />
        Like many businesses, we also collect information through cookies and similar technologies.
        <br />
        <br />
        The information we collect includes:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>
            Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information
            our servers automatically collect when you access or use our Services and which we record in log files.
            Depending on how you interact with us, this log data may include your IP address, device information,
            browser type, and settings and information about your activity in the Services (such as the date/time stamps
            associated with your usage, pages and files viewed, searches, and other actions you take such as which
            features you use), device event information (such as system activity, error reports (sometimes called
            (&ldquo;crash dumps(&ldquo;), and hardware settings).
          </li>
        </ul>
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">2.&nbsp;</span> HOW DO WE PROCESS YOUR INFORMATION?
      </div>
      <div className="text-md text-gray-700 mb-10">
        We process your personal information for a variety of reasons, depending on how you interact with our Services,
        including:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>
            To facilitate account creation and authentication and otherwise manage user accounts. We may process your
            information so you can create and log in to your account, as well as keep your account in working order.
          </li>
          <li>
            To deliver and facilitate delivery of services to the user. We may process your information to provide you
            with the requested service.
          </li>
          <li>
            To respond to user inquiries/offer support to users. We may process your information to respond to your
            inquiries and solve any potential issues you might have with the requested service.
          </li>
          <li>
            To send administrative information to you. We may process your information to send you details about our
            products and services, changes to our terms and policies, and other similar information.
          </li>
          <li>
            To fulfill and manage your orders. We may process your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Services.
          </li>
          <li>
            To enable user-to-user communications. We may process your information if you choose to use any of our
            offerings that allow for communication with another user.
          </li>
          <li>
            To request feedback. We may process your information when necessary to request feedback and to contact you
            about your use of our Services.
          </li>
          <li>
            To protect our Services. We may process your information as part of our efforts to keep our Services safe
            and secure, including fraud monitoring and prevention.
          </li>
          <li>
            To identify usage trends. We may process information about how you use our Services to better understand how
            they are being used so we can improve them.
          </li>
          <li>
            To save or protect an individual&apos;s vital interest. We may process your information when necessary to
            save or protect an individual&apos;s vital interest, such as to prevent harm.
          </li>
        </ul>
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">3.&nbsp;</span> WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
        INFORMATION?
      </div>
      <div className="text-md text-gray-700 mb-10">
        <div className="font-bold">If you are located in the EU or UK, this section applies to you.</div>
        <br />
        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on
        in order to process your personal information. As such, we may rely on the following legal bases to process your
        personal information:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>
            Consent. We may process your information if you have given us permission (i.e., consent) to use your
            personal information for a specific purpose. You can withdraw your consent at any time. Learn more about
            withdrawing your consent.
          </li>
          <li>
            Performance of a Contract. We may process your personal information when we believe it is necessary to
            fulfill our contractual obligations to you, including providing our Services or at your request prior to
            entering into a contract with you.
          </li>
          <li>
            Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve
            our legitimate business interests and those interests do not outweigh your interests and fundamental rights
            and freedoms. For example, we may process your personal information for some of the purposes described in
            order to:
          </li>
          <li>Analyze how our Services are used so we can improve them to engage and retain users</li>
          <li>Diagnose problems and/or prevent fraudulent activities</li>
          <li>Understand how our users use our products and services so we can improve user experience</li>
          <li>
            Legal Obligations. We may process your information where we believe it is necessary for compliance with our
            legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend
            our legal rights, or disclose your information as evidence in litigation in which we are involved.
          </li>
          <li>
            Vital Interests. We may process your information where we believe it is necessary to protect your vital
            interests or the vital interests of a third party, such as situations involving potential threats to the
            safety of any person.
          </li>
        </ul>
        <br />
        <div className="font-bold">If you are located in Canada, this section applies to you.</div>
        <br />
        We may process your information if you have given us specific permission (i.e., express consent) to use your
        personal information for a specific purpose, or in situations where your permission can be inferred (i.e.,
        implied consent). You can withdraw your consent at any time.
        <br />
        <br />
        In some exceptional cases, we may be legally permitted under applicable law to process your information without
        your consent, including, for example:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>
            If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
          </li>
          <li>For investigations and fraud detection and prevention</li>
          <li>For business transactions provided certain conditions are met</li>
          <li>
            If it is contained in a witness statement and the collection is necessary to assess, process, or settle an
            insurance claim
          </li>
          <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
          <li>
            If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
          </li>
          <li>
            If it is reasonable to expect collection and use with consent would compromise the availability or the
            accuracy of the information and the collection is reasonable for purposes related to investigating a breach
            of an agreement or a contravention of the laws of Canada or a province
          </li>
          <li>
            If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to
            the production of records
          </li>
          <li>
            If it was produced by an individual in the course of their employment, business, or profession and the
            collection is consistent with the purposes for which the information was produced
          </li>
          <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
          <li>If the information is publicly available and is specified by the regulations</li>
        </ul>
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">4.&nbsp;</span> WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
        INFORMATION?
      </div>
      <div className="text-md text-gray-700 mb-10">
        We may need to share your personal information in the following situations:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>
            Business Transfers. We may share or transfer your information in connection with, or during negotiations of,
            any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another
            company.
          </li>
        </ul>
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">5.&nbsp;</span> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </div>
      <div className="text-md text-gray-700 mb-10">
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
        information. Specific information about how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">6.&nbsp;</span> HOW LONG DO WE KEEP YOUR INFORMATION?
      </div>
      <div className="text-md text-gray-700 mb-10">
        We will only keep your personal information for as long as it is necessary for the purposes set out in this
        privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
        other legal requirements). No purpose in this notice will require us keeping your personal information for
        longer than the period of time in which users have an account with us.
        <br />
        <br />
        When we have no ongoing legitimate business need to process your personal information, we will either delete or
        anonymize such information, or, if this is not possible (for example, because your personal information has been
        stored in backup archives), then we will securely store your personal information and isolate it from any
        further processing until deletion is possible.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">7.&nbsp;</span> HOW DO WE KEEP YOUR INFORMATION SAFE?
      </div>
      <div className="text-md text-gray-700 mb-10">
        We have implemented appropriate and reasonable technical and organizational security measures designed to
        protect the security of any personal information we process. However, despite our safeguards and efforts to
        secure your information, no electronic transmission over the Internet or information storage technology can be
        guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or
        modify your information. Although we will do our best to protect your personal information, transmission of
        personal information to and from our Services is at your own risk. You should only access the Services within a
        secure environment.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">8.&nbsp;</span> DO WE COLLECT INFORMATION FROM MINORS?
      </div>
      <div className="text-md text-gray-700 mb-10">
        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
        represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
        minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of
        age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data
        from our records. If you become aware of any data we may have collected from children under age 18, please
        contact us at support@tokentable.xyz.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">9.&nbsp;</span> WHAT ARE YOUR PRIVACY RIGHTS?
      </div>
      <div className="text-md text-gray-700 mb-10">
        In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws.
        These may include the right (i) to request access and obtain a copy of your personal information, (ii) to
        request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if
        applicable, to data portability. In certain circumstances, you may also have the right to object to the
        processing of your personal information.
        <br />
        <br />
        We will consider and act upon any request in accordance with applicable data protection laws.
        <br />
        <br />
        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you
        also have the right to complain to your Member State data protection authority or UK data protection authority.
        <br />
        <br />
        If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
        <br />
        <br />
        Withdrawing your consent: If we are relying on your consent to process your personal information, which may be
        express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at
        any time. You can withdraw your consent at any time by contacting us by sending an email to
        support@tokentable.xyz
        <br />
        <br />
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when
        applicable law allows, will it affect the processing of your personal information conducted in reliance on
        lawful processing grounds other than consent.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">10.&nbsp;</span> ACCOUNT INFORMATION
      </div>
      <div className="text-md text-gray-700 mb-10">
        If you would at any time like to review or change the information in your account or terminate your account, you
        can:
        <br />
        <br />
        <ul className="list-disc pl-8">
          <li>Log in to your account settings and update your user account.</li>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <br />
        Upon your request to terminate your account, we will deactivate or delete your account and information from our
        active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems,
        assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
        <br />
        <br />
        Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can
        usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or
        reject cookies, this could affect certain features or services of our Services. You may also opt out of
        interest-based advertising by advertisers on our Services.
        <br />
        <br />
        If you have questions or comments about your privacy rights, you may email us at support@tokentable.xyz.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">11.&nbsp;</span> DO WE COLLECT INFORMATION FROM MINORS?
      </div>
      <div className="text-md text-gray-700 mb-10">
        California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&ldquo; law, permits our users
        who are California residents to request and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the
        names and addresses of all third parties with which we shared personal information in the immediately preceding
        calendar year. If you are a California resident and would like to make such a request, please submit your
        request in writing to us using the contact information provided below.
        <br />
        <br />
        If you are under 18 years of age, reside in California, and have a registered account with Services, you have
        the right to request removal of unwanted data that you publicly post on the Services. To request removal of such
        data, please contact us using the contact information provided below and include the email address associated
        with your account and a statement that you reside in California. We will make sure the data is not publicly
        displayed on the Services, but please be aware that the data may not be completely or comprehensively removed
        from all our systems (e.g., backups, etc.).
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">12.&nbsp;</span> DO WE MAKE UPDATES TO THIS NOTICE?
      </div>
      <div className="text-md text-gray-700 mb-10">
        We may update this privacy notice from time to time. The updated version will be indicated by an updated
        &ldquo;Revised&ldquo; date and the updated version will be effective as soon as it is accessible. If we make
        material changes to this privacy notice, we may notify you either by prominently posting a notice of such
        changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to
        be informed of how we are protecting your information.
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">13.&nbsp;</span> HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </div>
      <div className="text-md text-gray-700 mb-10">
        If you have questions or comments about this notice, you may email us at support@tokentable.xyz or by post to:
        <br />
        <br />
        Buildblock Tech Pte Ltd
        <br />
        <br />
        1 NORTH BRIDGE ROAD #21-10
        <br />
        <br />
        HIGH STREET CENTRE
        <br />
        <br />
        SINGAPORE, SINGAPORE 179094
        <br />
        <br />
        Singapore
      </div>
      <div className="text-display-xs font-semibold mb-4 flex">
        <span className="w-[35px] text-right -ml-2.5">14.&nbsp;</span> HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
        COLLECT FROM YOU?
      </div>
      <div className="text-md text-gray-700 mb-10">
        Based on the applicable laws of your country, you may have the right to request access to the personal
        information we collect from you, change that information, or delete it. To request to review, update, or delete
        your personal information, please visit: support@tokentable.xyz.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
