import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFooterLink, IFooterList } from 'types/Footer';

import { ETHSIGN_LINK, MEDIUM_LINK, TELEGRAM_LINK, TWITTER_LINK } from 'utils/constants';

import Medium from 'assets/images/Social/Medium.svg';
import Telegram from 'assets/images/Social/Telegram.svg';
import Twitter from 'assets/images/Social/Twitter.svg';
import { Logo } from 'components/Logo';

interface FooterProps {
  lists: IFooterList[];
}

const Link = ({ href, url, name }: IFooterLink) => {
  const navigate = useNavigate();

  const handleNavigate: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!href && url) {
      e.preventDefault();
      navigate(url);
    }
  };

  return (
    <li>
      <a
        className="p-1 text-[16px] font-medium text-black cursor-pointer whitespace-nowrap"
        href={href || url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleNavigate}
      >
        {name}
      </a>
    </li>
  );
};

export const Footer: React.FC<FooterProps> = ({ lists }) => {
  const year = new Date().getFullYear();

  const renderFooterList = (list: IFooterList) => (
    <div
      className="flex-1 max-w-[150px] xl:max-w-[180px] flex flex-col items-start w-fit xs:w-1/2 md:w-fit pb-4"
      key={list.title}
    >
      <span className="p-1 text-[12px] font-semiBold text-[rgb(120,119,133)] uppercase">{list.title}</span>
      <ul className="mt-4 space-y-5 text-black">
        {list.links.map((link) => (
          <Link key={link.name} {...link} />
        ))}
      </ul>
    </div>
  );

  return (
    <footer className="relative w-full text-black overflow-hidden">
      <div className="max-w-screen-xl mx-auto w-full px-4 md:px-8">
        <div className="flex flex-col items-start relative md:flex-row md:items-start">
          <div className="md:w-1/2 lg:w-1/2 text-left md:text-left">
            <a className="cursor-pointer inline-block" href={ETHSIGN_LINK} target="_blank" rel="noreferrer">
              <Logo className="md:max-w-[240px] lg:max-w-[540px]" />
            </a>
            <p className="mx-auto md:mx-0 mt-4 text-sm text-black text-left max-w-[540px]">
              TokenTable is a token ownership management platform that helps founders execute, track, and enforce the
              agreements a project uses to distribute their tokens.
            </p>
            <div className="flex flex-row justify-start items-center gap-3 mt-[22px] mb-[52px]">
              <div className="text-black text-[14px] md:text-[16px] font-medium">FOLLOW US:</div>
              <div className="flex items-center justify-start">
                <a href={TWITTER_LINK} target="_blank" rel="noopener noreferrer">
                  <img src={Twitter} className="w-[32px] md:w-[46px]" height="auto" alt="Twitter" />
                </a>
                <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
                  <img src={Telegram} className="w-[32px] md:w-[46px]" height="auto" alt="Telegram" />
                </a>
                <a href={MEDIUM_LINK} target="_blank" rel="noopener noreferrer">
                  <img src={Medium} className="w-[32px] md:w-[46px]" height="auto" alt="Medium" />
                </a>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-wrap md:flex-nowrap gap-4 relative  md:justify-end mt-0 md:mt-8  mb-0 w-full md:w-fit">
            {lists.map(renderFooterList)}
          </div>
        </div>
      </div>
      <div className="py-8 text-center text-[14px] md:text-sm text-black" style={{ borderTop: '1px solid #c7c7c7' }}>
        ©Copyright {year} EthSign | All rights reserved
      </div>
      <div className="absolute w-[128px] md:w-[98px] xl:w-[178px] h-[233px] md:h-[178px] xl:h-[324px] rounded-tr-[110px] bottom-[-176px] md:bottom-[-60px] xl:bottom-[-236px] left-0 bg-[#c4c4c408]"></div>
    </footer>
  );
};
