import { useState, useCallback } from 'react';
import classNames from 'classnames';

import { ArrowDownIcon } from 'components/Icons';
import { NavItem, INavItem } from './NavItem';

export interface INavMenu {
  menu: INavItem;
  subMenus?: INavItem[];
}

export interface INavList {
  menus: INavMenu[];
  align?: string;
}

export const NavMenu = ({ menu, subMenus }: INavMenu) => {
  const [isExpanded, setExpanded] = useState(false);

  const renderNavItem = useCallback((item: INavItem, index: number) => {
    return <NavItem {...item} key={`${item.name}-${index}`} />;
  }, []);

  return (
    <div
      className={classNames('flex flex-col lg:flex-row w-full lg:w-auto pb-2 lg:pb-0', {
        relative: menu.align === 'right'
      })}
    >
      <div
        data-hover=""
        data-delay="0"
        className="nav-link w-dropdown ml-0 mr-0 align-top lg:my-auto"
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
      >
        <div
          className={`cursor-pointer flex items-center text-md font-medium lg:mr-5 p-2 text-gray-600 hover:text-gray-900`}
          onClick={() => setExpanded(!isExpanded)}
        >
          <NavItem {...menu} isMenuItem={true} />
          <ArrowDownIcon className="ml-auto lg:ml-1" width={20} />
        </div>
        {isExpanded && subMenus && subMenus.length > 0 && (
          <div className="lg:absolute lg:pt-2">
            <nav
              className={classNames(
                'flex flex-col min-w-[171px] lg:shadow-xl select-none py-3 pl-2 lg:pl-0 mt-0 lg:rounded-lg overflow-hidden bg-white',
                { 'lg:right-1': menu.align === 'right' }
              )}
            >
              {subMenus.map(renderNavItem)}
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export const NavList = ({ menus, align }: INavList) => {
  const renderMenu = useCallback((item: INavMenu, index: number) => {
    if (item.subMenus && item.subMenus.length > 0) {
      return <NavMenu {...item} key={`${item.menu.name}-withsub-${index}`} />;
    }
    return <NavItem {...item.menu} key={`${item.menu.name}-withoutsub-${index}`} isMenuItem={true} />;
  }, []);

  return (
    <div
      className={classNames(
        'flex flex-col items-start w-full lg:flex-row lg:w-auto pb-2 lg:pb-0 px-4 md:px-8 lg:px-4 lg:relative lg:items-center lg:h-auto content-menu',
        { 'justify-end': align === 'right' },
        { 'justify-start': align === 'left' },
        { 'justify-center': align === 'center' }
      )}
    >
      <div className="ml-auto" />
      {menus.length > 0 && menus.map(renderMenu)}
    </div>
  );
};
