import FoundersSection from './sections/FoundersSection';
import LogosSection from './sections/LogosSection';
import TTIntroSection from './sections/TTIntroSection';

import { Footer } from 'components/Footer';
import { tokenTableFooter } from 'utils/footerMenus';
import FeaturesSection from './sections/FeaturesSection';
import InvestorsSection from './sections/InvestorsSection';
import QASection from './sections/QASection';
import SecuritySection from './sections/SecuritySection';
import TwitterFeedSection from './sections/TwitterFeedSection';

function Tokentable() {
  return (
    <div className="w-full h-full flex-col items-center justify-center">
      <TTIntroSection />
      <LogosSection />
      <div
        className="bg-footer-bg bg-100% w-full"
        style={{
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <FoundersSection />
        <InvestorsSection />
        <FeaturesSection />
        <SecuritySection />
        <TwitterFeedSection />
        {/* <InvestorLogosSection /> */}
        <QASection />
        <Footer lists={tokenTableFooter} />
      </div>
    </div>
  );
}

export default Tokentable;
