import { useEffect, useState } from 'react';

import { TwitterFeedSlide } from 'components/TwitterFeedSlide';
import { useWindowSize } from 'hooks';
import { getTwitterFeeds } from 'services/twitterFeeds';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TwitterFeed } from 'types/TwitterFeed';
import { Title } from 'components/Title';
import { LoadingIcon } from 'components/Loading/LoadingIcon';

const TwitterFeedSection = () => {
  const [twitterFeeds, setTwitterFeeds] = useState<TwitterFeed[]>([]);

  const size = useWindowSize();

  useEffect(() => {
    const mount = async () => {
      const feeds = await getTwitterFeeds();
      setTwitterFeeds(feeds);
    };

    mount();
  }, []);

  const twitterFeedsCombineTwo = [];
  for (let i = 0; i < twitterFeeds.length; ) {
    const temp = [];
    for (let j = 0; j < 2; j++) {
      if (j < twitterFeeds.length) {
        temp.push(twitterFeeds[i++]);
      }
    }
    temp.length && twitterFeedsCombineTwo.push(temp);
  }

  return (
    <section className="bg-white md:bg-transparent relative pt-[80px] w-full">
      <div className="flex flex-col w-full max-w-screen-xl mx-[auto] xs:px-[20px] 2xl:px-[0px]">
        <div className="flex flex-1 flex-col text-gray-900 w-full text-center justify-center">
          <span className="text-[32px] md:text-[40px] text-black font-bold">
            Community with <Title>TokenTable</Title>
          </span>
          <span className="text-[16px] mt-2 text-center">Enjoy TokenTable? Share your story!</span>
        </div>

        <div
          className="w-full min-h-[300px] md:min-h-[800px]"
          style={{
            visibility: twitterFeeds.length ? 'visible' : 'hidden',
            position: twitterFeeds.length ? 'relative' : 'absolute'
          }}
        >
          <Swiper
            modules={[Autoplay]}
            className="product-features-swiper swiper-h py-10 px-10 md:px-0"
            spaceBetween={20}
            slidesPerView={3}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true
            }}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              512: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 2
              },
              1024: {
                slidesPerView: 3
              }
            }}
          >
            {size.width && size.width <= 640
              ? twitterFeeds.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex flex-col justify-start flex-start w-100 h-auto gap-[24px] mx-[20px] md:mx-[0]">
                      <TwitterFeedSlide feed={slide} />
                    </div>
                  </SwiperSlide>
                ))
              : twitterFeedsCombineTwo.map((slideDataList, index) => (
                  <SwiperSlide key={index}>
                    <div className="flex flex-col justify-start flex-start w-100 h-auto gap-[24px] mx-[20px] md:mx-[0]">
                      <>
                        {slideDataList.map((s) => {
                          return <TwitterFeedSlide key={s.id} feed={s} />;
                        })}
                      </>
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>

        <div
          className="w-full flex justify-center items-center h-[300px] md:h-[800px]"
          style={{ display: twitterFeeds.length ? 'none' : 'flex' }}
        >
          <LoadingIcon className="text-[60px] w-[30px] h-[30px]" />
        </div>
      </div>
    </section>
  );
};

export default TwitterFeedSection;
