import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import { useWindowSize } from 'hooks';

import Logo1 from 'assets/images/Logos/p1.png';
import Logo2 from 'assets/images/Logos/p2.png';
import Logo3 from 'assets/images/Logos/p3.png';
import Logo4 from 'assets/images/Logos/p4.png';
import Logo6 from 'assets/images/Logos/p6.png';
import Logo7 from 'assets/images/Logos/p7.png';
import Logo8 from 'assets/images/Logos/p8.png';
import Logo9 from 'assets/images/Logos/p9.png';
import Logo10 from 'assets/images/Logos/p10.png';
import Logo11 from 'assets/images/Logos/p11.png';
import Logo12 from 'assets/images/Logos/p12.png';

const LOGOS = [
  {
    name: '1',
    image: Logo1
  },
  {
    name: 'Mocaverse',
    image: Logo2
  },
  {
    name: 'aspecta',
    image: Logo3
  },
  {
    name: 'karatdao',
    image: Logo4
  },
  {
    name: 'mirror world',
    image: Logo6
  },
  {
    name: 'Impossible Finance',
    image: Logo7
  },
  {
    name: 'Luanchecaster',
    image: Logo8
  },
  {
    name: 'Manta',
    image: Logo9
  },
  {
    name: 'Galxe',
    image: Logo10
  },
  {
    name: 'Guild',
    image: Logo11
  },
  {
    name: 'DELY',
    image: Logo12
  }
];

const LogosSection = () => {
  const { width } = useWindowSize();
  const slidesPerView = width && width <= 640 ? 3 : 6;
  const loop = width && width <= 640 ? true : false;

  return (
    <section className="relative founders-section bg-white md:bg-text-gradient-bg w-full h-full pt-[40px] md:pt-[80px]">
      <div className="w-full text-center text-text text-[16px] font-semiBold">PEOPLE WHO TRUST US</div>
      <div className="max-w-screen-2xmx-auto px-10 md:px-20 mb-[20px]">
        <Swiper
          modules={[Autoplay]}
          slidesPerView={slidesPerView}
          autoplay={{ delay: 3000 }}
          loop={loop}
          className="flex h-[80px] sm:h-[100px] items-center gap-[45px]"
        >
          {LOGOS.map((logo, index) => (
            <SwiperSlide key={index} className="flex items-center justify-center">
              <img className="w-[190px]" height={'auto'} src={logo.image} alt={logo.name} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default LogosSection;
