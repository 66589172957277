import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, ButtonVariant } from 'components/Button';
import { FeatherIcon, MediumIcon, TelegramIcon, TwitterIcon } from 'components/Icons';
import { Logo } from 'components/Logo';
import {
  FAQ_LINK,
  FEATURE_REQUEST_LINK,
  MEDIUM_LINK,
  START_LINK,
  TELEGRAM_LINK,
  TROUBLE_SHOOTING_LINK,
  TUTORIAL_LINK,
  TWITTER_LINK
} from 'utils/constants';
import { hideDocumentScroll, resetDocumentScroll } from 'utils/utils';
import { NavList } from './NavList';

export const Navbar = () => {
  const [isExpanded, setExpanded] = useState(false);
  const navigateRoute = useNavigate();

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (isExpanded) {
      hideDocumentScroll();
    } else {
      resetDocumentScroll();
    }
  }, [isExpanded]);

  const openLink = (link: string) => {
    window.open(link, '_blank');
  };

  const menus = useMemo(() => {
    return [
      {
        menu: { name: 'Product', align: 'right' },
        subMenus: [
          { name: 'Feature request', href: FEATURE_REQUEST_LINK, target: '_blank' },
          { name: 'FAQs', href: FAQ_LINK, target: '_blank' },
          { name: 'Troubleshooting', href: TROUBLE_SHOOTING_LINK, target: '_blank' }
        ]
      },
      {
        menu: { name: 'Community', align: 'right' },
        subMenus: [
          { icon: <TwitterIcon />, name: 'Twitter', href: TWITTER_LINK, target: '_blank' },
          { icon: <TelegramIcon />, name: 'Telegram', href: TELEGRAM_LINK, target: '_blank' },
          { icon: <MediumIcon />, name: 'Medium', href: MEDIUM_LINK, target: '_blank' }
        ]
      },
      {
        menu: { name: 'Docs', href: TUTORIAL_LINK, target: '_blank' }
      }
    ];
  }, []);

  return (
    <nav className="bg-white whitespace-nowrap absolute top-[0px] left-[0px] w-full shadow-[0px_1px_10px_0px_rgba(0,0,0,0.05)] xl:shadow-none">
      <div className="max-w-screen-xl mx-auto w-full px-[0px] py-[12px] md:py-[42px] md:px-[17px] lg:px-[60px] relative z-30 md:pb-[18px] md:pt-[17px]">
        <div className="flex justify-between items-center relative lg:justify-start px-4 md:px-8 lg:px-4">
          <Logo className="cursor-pointer" onClick={() => navigateRoute('/')} />
          <div className="basis-full flex flex-row justify-end items-center">
            <div className="hidden lg:block mr-[80px]">
              <NavList menus={menus} align="right" />
            </div>
            <div className="flex gap-4 ml-auto lg:ml-0">
              <Button
                containerClassName="ml-auto lg:ml-0 hidden md:block"
                className="rounded-[100px] w-[143px]"
                variant={ButtonVariant.PRIMARY}
                onClick={() => openLink(START_LINK)}
              >
                Get Started
              </Button>
            </div>
          </div>
          <FeatherIcon className="curser-pointer ml-5 w-8 h-9 lg:hidden" onClick={toggleExpanded} />
          {isExpanded && (
            <div
              className={classNames(
                'absolute z-20 right-0 left-0 top-12 md:top-16 h-[100vh] mt-0 pb-4 shadow-[0_10px_10px_rgba(0,0,0,0.1)] w-full lg:hidden bg-white'
              )}
            >
              <div className="flex flex-col justify-end items-center relative w-full">
                <NavList menus={menus} align="right" />
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
