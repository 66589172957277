export const navigate = (url: string): void => {
  window.open(url, '_blank', 'noopener noreferrer');
};

export const hideDocumentScroll = (): void => {
  document.body.style.overflow = 'hidden';
};

export const resetDocumentScroll = (): void => {
  document.body.style.overflow = 'unset';
};
