export const TelegramIcon = ({ ...props }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_8877_94642)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.64987 11.5623C8.09231 8.75543 12.3883 6.90497 14.5378 6.01092C20.675 3.45823 21.9503 3.0148 22.7815 3.00016C22.9643 2.99694 23.3731 3.04224 23.6379 3.25709C23.8614 3.43851 23.9229 3.68357 23.9524 3.85557C23.9818 4.02757 24.0185 4.41939 23.9893 4.72555C23.6568 8.21999 22.2177 16.7001 21.4856 20.6139C21.1758 22.27 20.5658 22.8253 19.9753 22.8796C18.6919 22.9977 17.7174 22.0315 16.4744 21.2167C14.5294 19.9417 13.4305 19.148 11.5425 17.9038C9.36064 16.466 10.7751 15.6757 12.0185 14.3842C12.344 14.0462 17.9985 8.90304 18.1079 8.43647C18.1216 8.37812 18.1343 8.16061 18.0051 8.04576C17.8759 7.93091 17.6851 7.97018 17.5475 8.00142C17.3524 8.04569 14.2452 10.0995 8.22584 14.1627C7.34386 14.7683 6.545 15.0634 5.82924 15.0479C5.04018 15.0309 3.52233 14.6018 2.39397 14.235C1.00999 13.7851 -0.0899676 13.5473 0.00581423 12.7832C0.0557033 12.3853 0.603723 11.9783 1.64987 11.5623Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_8877_94642">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
