import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Navbar } from 'components/Navbar';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfService from 'pages/ToS';
import Tokentable from 'pages/index';
import { AppRoutes } from 'routes';
import 'swiper/css/bundle';
import { tokenTableFooter } from 'utils/footerMenus';
import { Footer } from 'components/Footer';

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="w-full bg-white min-h-screen overflow-x-hidden relative">
      <div className="mx-auto w-full fixed bg-white z-50">
        <Navbar />
      </div>
      <div className="mt-[57px] md:mt-[76px]">
        <Routes>
          <Route path={AppRoutes.PrivacyPolicy} element={<PrivacyPolicy />} />
          <Route path={AppRoutes.TermsOfService} element={<TermsOfService />} />
          <Route path={AppRoutes.Home} element={<Tokentable />} />
        </Routes>
        {pathname !== '/' && <Footer lists={tokenTableFooter} />}
      </div>
    </div>
  );
}

export default App;
