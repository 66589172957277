import { AUDIT_LINK } from 'utils/constants';

const QASection = () => {
  return (
    <section className="pt-[64px] md:pt-0 pb-[80px] relative w-full">
      <div className="flex flex-col max-w-screen-xl mx-auto px-4 md:px-6">
        <div className="flex">
          <span className="text-[24px] md:text-[40px] font-bold text-black sm:text-h1">Frequently asked questions</span>
        </div>
        <div className="flex flex-col md:flex-row flex-start items-start mt-6 md:mt-12 gap-[24px] md:gap-24">
          <div className="w-full md:w-[50%]">
            <div className="flex flex-1 flex-col">
              <div className="flex flex-row justify-between items-center text-[18px] font-bold font-inter text-black">
                <span>How does TokenTable ensure the security of my tokens?</span>
              </div>
              <span className="text-[16px] mt-[8px] text-black font-normal font-inter">
                The TokenTable Unlocker smart contract is{' '}
                <a
                  href={AUDIT_LINK}
                  target="_blank"
                  referrerPolicy="no-referrer"
                  style={{ textDecoration: 'underline' }}
                  rel="noreferrer"
                >
                  audited
                </a>{' '}
                by Ottersec and others. The Unlocker contract only allows addresses whitelisted by the the project to
                withdraw tokens.
              </span>
            </div>

            <div className="flex flex-1 flex-col mt-8">
              <div className="flex flex-row justify-between items-center text-[18px] font-bold font-inter text-black">
                <span>How does FutureTokens work?</span>
              </div>
              <span className="mt-[8px] text-black font-inter text-[16px]">
                FutureTokens are redemption NFTs minted to stakeholders to represent token ownership.
                <br />
                TrackerToken is a non-transferrable ERC20 convenience token that shows stakeholders their balance of
                claimable, unlocked tokens.
                <br />
                Investors can manage their token ownership across all projects in their portfolio, across all supported
                chains.
              </span>
            </div>
          </div>
          <div className="w-full md:w-[50%]">
            <div className="flex flex-col">
              <div className="flex flex-1 flex-col">
                <div className="flex flex-row justify-between items-center text-[18px] font-bold font-inter text-black">
                  <span>What differentiates TokenTable from other token management platforms?</span>
                </div>
                <span className="text-[16px] mt-[8px] text-black font-normal font-inter">
                  TokenTable will generate a distinct smart contract for each project, granting founders the ability to
                  create various unlocking schedules with multiple recipients. TokenTable offers founders a broader
                  array of flexible options, including determining whether the distribution is cancelable and whether
                  the future tokens are transferable.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QASection;
