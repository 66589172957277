export const ArrowRight = ({ ...props }) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.49984 2.66666L7.55984 3.60666L11.2798 7.33332H3.1665V8.66666H11.2798L7.55984 12.3933L8.49984 13.3333L13.8332 7.99999L8.49984 2.66666Z"
        fill="currentColor"
      />
    </svg>
  );
};
