import FeatureOne from 'assets/images/Features/feature-one.png';
import FeatureTwo from 'assets/images/Features/feature-two.png';
import FeatureThree from 'assets/images/Features/feature-three.png';
import { Title } from 'components/Title';

const feautList = [
  {
    name: 'Trustless Execution',
    content:
      'Token unlocking and distribution are executed by smart contracts that you deploy and own, ensuring your tokens are never custodied by third parties.',
    image: FeatureOne
  },
  {
    name: 'Manage Token Equity Across Projects',
    content: 'Advanced token distribution controls and analytics for founders, investors, teams, and advisors.',
    image: FeatureTwo
  },
  {
    name: 'Back Office Composability',
    content:
      'Bulk upload all token holder addresses via CSV to quickly set up your token distribution. Download all data generated by TokenTable to sync with your back-office stack.',
    image: FeatureThree
  }
];

const FeaturesSection = () => {
  return (
    <section className="bg-white md:bg-transparent w-full relative pb-[64px] md:pb-0">
      <div className="max-w-screen-xl mx-auto px-4 md:px-6 lg:px-20">
        <div className="flex flex-col items-center">
          <div className="w-full text-center text-text text-[16px] font-semiBold pt-[24px] md:pt-[80px]">
            Easy to Use
          </div>
          <h2 className="sm:max-w-[640px] text-center font-bold text-gray-900 text-[32px] md:text-[38px] mx-auto mt-4">
            Say goodbye to <Title>spreadsheets</Title>; Regain control of your cap table
          </h2>
          <div className="w-full text-center text-black text-[16px] font-medium mt-3">
            Turn legal consent into verifiable actions using smart contracts.
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[40px]">
            {feautList.map((feature, index) => {
              return (
                <div className="flex flex-col flex-start items-center mt-[20px]" key={index}>
                  <div className="w-[210px] h-[200px] flex justify-center items-center">
                    <img src={feature.image} alt="feature-icon" width="auto" height="auto" />
                  </div>
                  <div className="font-bold text-[18px] text-black">{feature.name}</div>
                  <div className="mt-[12px] md:mt-[20px] text-[16px] font-medium text-black text-center">
                    {feature.content}
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 mt-10 sm:mt-16">
            <div className="flex flex-col items-center mt-10 sm:mt-0 sm:mx-4 lg:mx-6 order-2 sm:order-1">
              <img
                src={FundTransfer}
                className="h-[240px] lg:h-[280px] rounded-xl md:drop-shadow"
                alt="On-chain Fund Transfer"
              />
              <h3 className="font-semiBold text-xl text-gray-900 mt-10 text-center">On-chain Fund Transfer</h3>
              <p className="text-md font-normal text-gray-900 mt-2 text-center">
                Couple signing investment contracts with on-chain fund transfers.
              </p>
            </div>
            <div className="flex flex-col items-center mt-10 sm:mt-0 sm:mx-4 lg:mx-6 order-4 sm:order-2">
              <img
                src={SmartTemplate}
                className="h-[240px] lg:h-[280px] rounded-xl md:drop-shadow"
                alt="Smart Templates"
              />
              <h3 className="font-semiBold text-xl text-gray-900 mt-10 text-center">Smart Templates</h3>
              <p className="text-md font-normal text-gray-900 mt-2 text-center">
                Create contracts with Smart Templates, a library of legal contracts reviewed by attorneys from top law
                firms, VCs, and DAOs.
              </p>
            </div>
            <div className="flex flex-col items-center mt-10 sm:mx-4 lg:mx-6 order-3">
              <img src={SignPay} className="h-[240px] lg:h-[280px] rounded-xl md:drop-shadow" alt="Sign & Pay" />
              <h3 className="font-semiBold text-xl text-gray-900 mt-10 text-center">Sign & Pay</h3>
              <p className="text-md font-normal text-gray-900 mt-2 text-center">
                Sign contracts, wire funds, and claim tokens using your address.
              </p>
            </div>
            <div className="flex flex-col items-center sm:mt-10 sm:mx-4 lg:mx-6 order-1 sm:order-4">
              <img
                src={MultisigSupport}
                className="h-[240px] lg:h-[280px] rounded-xl md:drop-shadow"
                alt="Multisig Support"
              />
              <h3 className="font-semiBold text-xl text-gray-900 mt-10 text-center">Multisig Support</h3>
              <p className="text-md font-normal text-gray-900 mt-2 text-center">
                Connect your multi-sig wallet to track token balances and transactions.
              </p>
            </div>
            <div className="flex flex-col items-center mt-10 sm:mx-4 lg:mx-6 order-5">
              <img
                src={DataStorage}
                className="h-[240px] lg:h-[280px] rounded-xl md:drop-shadow"
                alt="Decentralized Data Storage & Security"
              />
              <h3 className="font-semiBold text-xl text-gray-900 mt-10 text-center">
                Decentralized Data Storage & Security{' '}
                <span className="text-xs font-medium text-white bg-primary-700 rounded-md px-2 py-1 whitespace-nowrap">
                  Coming Soon
                </span>
              </h3>
              <p className="text-md font-normal text-gray-900 mt-2 text-center">
                Store your token cap table and contract data in decentralized storage, fully encrypted using threshold
                cryptography.
              </p>
            </div>
            <div className="flex flex-col items-center mt-10 sm:mx-4 lg:mx-6 order-6">
              <img
                src={AccessControl}
                className="h-[240px] lg:h-[280px] rounded-xl md:drop-shadow"
                alt="Access Control"
              />
              <h3 className="font-semiBold text-xl text-gray-900 mt-10 text-center">
                Access Control{' '}
                <span className="text-xs font-medium text-white bg-primary-700 rounded-md px-2 py-1 whitespace-nowrap">
                  Coming Soon
                </span>
              </h3>
              <p className="text-md font-normal text-gray-900 mt-2 text-center">
                Assign administrator, read & write, and view access controls.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
