import { FC } from 'react';

import { ArrowRight } from 'components/Icons/ArrowRightIcon';

import { DemoIcon } from 'components/Icons/DemoIcon';
import { REQUEST_DEMO_LINK, START_LINK } from 'utils/constants';
import { navigate } from 'utils/utils';
import { Title } from 'components/Title';

import centerBg from 'assets/images/center-bg.png';
import { useWindowSize } from 'hooks';

const TTIntroSection: FC = () => {
  const { width, height } = useWindowSize();
  const handleTry = () => navigate(START_LINK);
  const handleRequestDemo = () => navigate(REQUEST_DEMO_LINK);

  return (
    <section
      className="pt-12 xl:pt-[80px] relative w-full bg-100% md:bg-center xl:bg-100% bg-h5-header-banner-bg md:bg-header-banner-bg h-[650px]"
      style={{
        backgroundPosition: 'bottom center',
        backgroundRepeat: 'no-repeat',
        height: !width || width < 768 ? '650px' : 'calc(100vh - 80px)'
      }}
    >
      <div className="max-w-screen-xl mx-auto px-[22px] md:px-0 lg:px-20 relative z-[1]">
        <div className="flex flex-col items-center text-center">
          <div className="text-[#101828] font-medium text-[32px]">
            Built For <span className="text-text font-bold">Founders</span>
          </div>
          <h1 className="font-mont font-bold text-black text-[40px] md:text-[52px] leading-snug md:leading-snug md:text-5xl mb-2 mt-[12px]">
            The <Title>Leading</Title> Token
            <br />
            Management Platform
          </h1>
          <p className="text-[16px] font-medium text-black mt-[16px]">
            Unlock, distribute, and manage your tokens securely, ensuring optimal future liquidity
          </p>
          {/* <div className="flex items-baseline text-3xl md:text-4xl mb-6">
            <span className="leading-snug text-gray-900">Built For</span>&nbsp;
            <Swiper
              direction="vertical"
              modules={[Autoplay]}
              autoplay={{
                delay: 5000
              }}
              loop
              className="h-12 font-bold text-tangerine-500 leading-snug text-left"
            >
              <SwiperSlide>Founders</SwiperSlide>
              <SwiperSlide>Investors</SwiperSlide>
              <SwiperSlide>Community</SwiperSlide>
            </Swiper>
          </div> */}

          <div className="flex flex-col md:flex-row justify-center items-center w-full mt-[32px] gap-[16px]">
            <button
              className="flex flex-row font-semiBold bg-tangerine-500 text-[16px] text-white items-center justify-center px-4 h-[48px] rounded-[100px] w-[242px] md:w-[auto]"
              onClick={handleTry}
            >
              Get Started for Free
              <ArrowRight className="ml-3" />
            </button>
            <button
              className="flex flex-row font-semiBold bg-transparent text-[16px] border border-black text-black items-center justify-center px-4 py-[10px] rounded-[100px] w-[242px] md:w-[auto]"
              onClick={handleRequestDemo}
            >
              <DemoIcon color="black" className="mr-3" />
              Request Demo
            </button>
          </div>
        </div>
      </div>
      <div className="hidden md:flex justify-center items-center absolute left-[-80px] bottom-[40px] w-full z-[0]">
        {height && height > 840 && <img src={centerBg} className="max-w-[525px] lg:max-w-[625px]" />}
      </div>
    </section>
  );
};

export default TTIntroSection;
