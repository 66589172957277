import { ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';

export interface INavItem {
  name: string;
  icon?: string | React.ReactNode;
  path?: string;
  isNew?: boolean;
  isMenuItem?: boolean;
  onClick?: () => void;
  href?: string;
  target?: string;
  current?: boolean;
  children?: INavItem[];
  hasSubItems?: boolean;
  align?: string;
}

export interface ILinkProps {
  href?: string;
  target?: string;
}

export interface ILinkItem {
  name: string;
  link: ILinkProps;
  icon?: string | ReactNode;
  hasSubItems?: boolean;
  isMenuItem?: boolean;
}

export const LinkItem = ({ icon, name, link, hasSubItems, isMenuItem }: ILinkItem) => {
  return (
    <a
      {...link}
      rel="noopener noreferrer"
      className={classNames(
        'flex items-center py-4 hover:bg-gray-100 text-md font-medium',
        {
          'w-dropdown-link': hasSubItems
        },
        isMenuItem ? 'text-gray-600 px-2 py-1' : 'text-gray-500 px-0 lg:px-5'
      )}
      role="menuitem"
    >
      {icon && <span className="mr-4">{icon}</span>}
      <span>{name}</span>
    </a>
  );
};

export const NavItem = ({ icon, name, href, target, hasSubItems, isMenuItem, onClick }: INavItem) => {
  const linkProps = useMemo(() => {
    const props: ILinkProps = {};
    if (href) {
      props['href'] = href;
    }
    if (target) {
      props['target'] = target;
    }
    return props;
  }, [href, target]);

  const handleClick = useCallback(() => {
    onClick && onClick();
  }, [onClick]);

  if (href) {
    return <LinkItem icon={icon} link={linkProps} name={name} hasSubItems={hasSubItems} isMenuItem={isMenuItem} />;
  }

  if (onClick) {
    return (
      <div
        className={classNames(
          'cursor-pointer text-md font-medium',
          { 'w-dropdown-link': hasSubItems },
          isMenuItem ? 'text-gray-600' : 'text-gray-500'
        )}
        role="menuitem"
        tabIndex={-1}
        onClick={handleClick}
      >
        {icon && <span className="mr-4">{icon}</span>}
        <span className="text-md text-gray-500">{name}</span>
      </div>
    );
  }

  return (
    <span className="text-md font-medium flex items-center">
      {icon && <span className="mr-4">{icon}</span>} {name}
    </span>
  );
};
