import { SVGProps } from 'react';

export const Logo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="232" height="40" viewBox="0 0 232 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M66.5279 15.2605V11.001H49.8081V15.2605H55.934V31.001H60.3739V15.2605H66.5279Z" fill="#373B46" />
      <path
        d="M72.2376 20.9869C72.2376 16.812 75.2162 14.922 77.9982 14.922C80.752 14.922 83.7307 16.812 83.7307 20.9869C83.7307 25.1618 80.752 27.0235 77.9982 27.0235C75.2162 27.0235 72.2376 25.1618 72.2376 20.9869ZM67.6291 21.0151C67.6291 27.3338 72.3781 31.001 77.9982 31.001C83.5902 31.001 88.3391 27.3338 88.3391 21.0151C88.3391 14.6681 83.5902 11.001 77.9982 11.001C72.3781 11.001 67.6291 14.6681 67.6291 21.0151Z"
        fill="#373B46"
      />
      <path
        d="M103.558 31.001H109.094L101.31 19.6611L109.066 11.001H103.221L95.943 19.4072V11.001H91.5031V31.001H95.943V25.6413L98.191 23.1307L103.558 31.001Z"
        fill="#373B46"
      />
      <path
        d="M124.181 31.001V26.7697H115.976V22.9333H123.394V19.0123H115.976V15.2041H124.153V11.001H111.536V31.001H124.181Z"
        fill="#373B46"
      />
      <path
        d="M145.228 31.001V11.001H140.788V23.1589L133.314 11.001H127.918V31.001H132.33V17.8557L140.536 31.001H145.228Z"
        fill="#373B46"
      />
      <path d="M164.605 15.2605V11.001H147.885V15.2605H154.011V31.001H158.451V15.2605H164.605Z" fill="#373B46" />
      <path
        d="M178.807 31.001H183.64L176.221 11.001H171.079L163.576 31.001H168.241L169.674 26.9389H177.374L178.807 31.001ZM173.58 15.9657L175.969 22.8769H171.135L173.58 15.9657Z"
        fill="#373B46"
      />
      <path
        d="M186.095 11.001V31.001H194.132C197.897 31.001 200.37 28.6032 200.37 25.331C200.37 22.7358 198.628 21.0715 196.83 20.6484C198.459 20.1124 199.752 18.4199 199.752 16.3606C199.752 13.2859 197.476 11.001 193.542 11.001H186.095ZM190.423 19.0123V14.6963H192.783C194.525 14.6963 195.396 15.5426 195.396 16.8402C195.396 18.1942 194.385 19.0123 192.839 19.0123H190.423ZM190.423 27.362V22.6794H193.205C194.947 22.6794 195.958 23.6385 195.958 25.0207C195.958 26.4594 194.919 27.362 193.205 27.362H190.423Z"
        fill="#373B46"
      />
      <path d="M216.477 31.001V26.7415H207.878V11.001H203.438V31.001H216.477Z" fill="#373B46" />
      <path
        d="M232 31.001V26.7697H223.795V22.9333H231.213V19.0123H223.795V15.2041H231.972V11.001H219.355V31.001H232Z"
        fill="#373B46"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9232 40C30.9266 40 39.8465 31.0457 39.8465 20C39.8465 8.9543 30.9266 0 19.9232 0C8.91994 0 0 8.9543 0 20C0 31.0457 8.91994 40 19.9232 40ZM16.1876 15H9.96162V21.25H16.1876V15ZM23.0363 15V30H16.8102V15H23.0363ZM29.8849 10H23.6589V16.25H29.8849V10Z"
        fill="#EF6820"
      />
    </svg>
  );
};
